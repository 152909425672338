import { createApp } from 'vue';
import './registerServiceWorker';
// Font awesome
// @ts-ignore
import FontAwesomeIcon from '@/plugins/font-awesome';
import router from './router';
import App from './App.vue';

// Firebase
import '@/plugins/firebase';

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .mount('#app');
