<template>
  <main class="client-page">
    <div class="container is-fluid">
      <!-- Upper actions bar -->
      <section class="actions is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
        <div>
          <h3 class="title is-4">Client management</h3>
        </div>
        <div>
          <button v-on:click="newClient()" class="button is-primary"><font-awesome-icon icon="plus" class="mr-2"></font-awesome-icon> Add Client</button>
        </div>
      </section>
      <!-- Available clients -->
      <section class="clients mt-5">
        <ul>
          <li class="client" v-for="client in clients" v-bind:key="client">
            <div class="client-name is-flex is-flex-direction-row is-align-items-center is-align-content-center is-justify-content-space-between">
              <p>{{client?.name}}</p>
              <button class="button is-small is-rounded" v-on:click="viewingThisClient(client); viewClient = true;">
                <span class="icon is-small">
                  <font-awesome-icon icon="ellipsis-h"></font-awesome-icon>
                </span>
              </button>
            </div>
            <div class="client-info pt-2">
              <p><font-awesome-icon icon="mail-bulk"></font-awesome-icon> {{client?.email}}</p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  </main>
  <!-- App Modals -->
  <!-- Creating new client -->
  <div class="modal is-flex" v-if="creatingClient">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p>Creating New Client</p>
          </div>
        </div>
        <div class="form-section">
          <form @submit.prevent="submitNewClient">
            <!-- Client name -->
            <div class="field">
              <label class="label" for="client-name">Client Name</label>
              <div class="control">
                <input id="client-name" tabindex="1" class="input" v-bind:class="{'is-danger': clientNameError === true}" type="text" placeholder="" v-model="newClientObj.name">
                <p class="help is-danger" v-if="clientNameError === true">
                  Please enter a client name
                </p>
              </div>
            </div>
            <!-- Client Email -->
            <div class="field">
              <label class="label" for="client-email">Client Email</label>
              <div class="control">
                <input id="client-email" tabindex="2" class="input" v-bind:class="{'is-danger': clientEmailError === true}" type="email" placeholder="" v-model="newClientObj.email"/>
                <p class="help is-danger" v-if="clientEmailError === true">
                  Please enter an email
                </p>
              </div>
            </div>
            <!-- Password -->
            <div class="field">
              <label class="label" for="client-pass">Password:</label>
              <div class="control">
                <input id="client-pass" tabindex="3" class="input" v-bind:class="{'is-danger': clientPasswordError === true}" type="password" v-model="newClientObj.password"/>
                <p class="help is-danger" v-if="clientPasswordError === true">
                  Please enter a password
                </p>
              </div>
            </div>
            <!-- Confirm password -->
            <div class="field">
              <label class="label" for="confirm-pass">Confirm Password:</label>
              <div class="control">
                <input tabindex="4" class="input" v-bind:class="{'is-danger': clientConfirmPasswordError === true || passwordsMisMatch === true}" id="confirm-pass" type="password" v-model="newClientObj.confirmedPassword"/>
                <p class="help is-danger" v-if="clientConfirmPasswordError === true">
                  Please enter the password again
                </p>
                <p class="help is-danger" v-if="passwordsMisMatch === true">
                  Passwords must match
                </p>
              </div>
            </div>
            <div class="field is-grouped is-grouped-right">
              <div class="control">
                <a class="button is-danger is-outlined" v-on:click="creatingClient = false;">Cancel</a>
              </div>
              <div class="control">
                <button tabindex="5" class="button is-primary" :disabled="newClientObj.name === '' || newClientObj.email === '' || newClientObj.password === '' || newClientObj.password !== newClientObj.confirmedPassword">Create</button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
  <!-- View client data -->
  <div class="modal is-flex" v-if="viewClient">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body" v-if="!updatingClientInformation">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Viewing: {{clientBeingViewed.name}}</p>
            <button class="delete" aria-label="close" v-on:click="viewClient = false;"></button>
          </div>
        </div>
        <div class="modal-actions mt-3">
          <div class="buttons mt-3">
            <button class="button is-info is-fullwidth" v-on:click="viewThisClient(clientBeingViewed)">View Client</button>
            <button class="button is-link is-light is-fullwidth" v-on:click="updatingClientInformation = true">Update client</button>
            <button class="button is-warning is-fullwidth is-light" v-bind:class="{'is-loading': this.updatingClient === true}" v-on:click="setClientStatus('Inactive')" v-if="clientBeingViewed.isActive === true">Set Inactive</button>
            <button class="button is-success is-fullwidth is-light" v-bind:class="{'is-loading': this.updatingClient === true}" v-on:click="setClientStatus('Active')" v-if="clientBeingViewed.isActive === false">Set Active</button>
          </div>
        </div>
      </section>
      <section class="modal-card-body" v-if="updatingClientInformation">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Updating information: {{clientBeingViewed.name}}</p>
            <button class="delete" aria-label="close" v-on:click="updatingClientInformation = false; this.errorState = false; this.errorMessage = ''"></button>
          </div>
        </div>
        <div class="form-section">
          <form @submit.prevent="updateClientInformation">
            <!-- Client name -->
            <div class="field">
              <label class="label" for="client-name">Client Name</label>
              <div class="control">
                <input id="client-name" tabindex="1" class="input" type="text" placeholder="{{clientBeingViewed?.name}}" v-model="clientBeingViewed.name">
              </div>
            </div>
            <!-- Client Email -->
            <div class="field">
              <label class="label" for="client-email">Client Email</label>
              <div class="control">
                <input id="client-email" tabindex="2" class="input" type="email" placeholder="{{clientBeingViewed?.email}}" v-model="clientBeingViewed.email"/>
              </div>
            </div>
            <!-- Password -->
            <div class="field">
              <label class="label" for="client-pass">Password: <i class="disclaimer" style="">( Re-enter password below before updating )</i></label>
              <div class="control">
                <input id="client-pass" tabindex="3" class="input" type="password" v-model="clientBeingViewed.password"/>
              </div>
            </div>
            <div class="field is-grouped is-grouped-right">
              <div class="control">
                <a class="button is-danger is-outlined" v-on:click="updatingClientInformation = false; this.errorState = false; this.errorMessage = ''">Cancel</a>
              </div>
              <div class="control">
                <button tabindex="5" class="button is-primary" v-bind:class="{'is-loading': this.updatingClient === true}">Update</button>
              </div>
            </div>
          </form>
        </div>
        <div class="errors" v-if="errorState">
          {{errorMessage}}
        </div>
      </section>
    </div>
  </div>
  <!-- App statuses -->
  <!-- Success -->
  <div class="notification is-success is-light" v-if="successState">
    <button class="delete" v-on:click="successState = !successState"></button>
    <p class="title is-5"><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> Success</p>
    <p>{{successMessage}}</p>
  </div>
  <!-- Warning -->
  <div class="notification is-warning is-light" v-if="warningState">
    <button class="delete" v-on:click="warningState = !warningState"></button>
    <p class="title is-5"><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> Caution</p>
    <p>{{warningMessage}}</p>
  </div>
  <!-- Error -->
  <div class="notification is-danger is-light" v-if="errorState">
    <button class="delete" v-on:click="errorState = !errorState"></button>
    <p class="title is-5"><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> Something went wrong</p>
    <p>{{errorMessage}}</p>
  </div>
</template>

<script>
// Firebase imports
import firebase from 'firebase/app';
// import 'firebase/auth';
import 'firebase/database';
// eslint-disable-next-line import/no-cycle
import router from '../../router';

export default {
  name: 'clientManagement',
  data() {
    // Return defualt values and app states
    return {
      // App states
      errorState: false,
      errorMessage: '',
      warningState: false,
      warningMessage: '',
      successState: false,
      successMessage: '',
      // Data object
      clients: [],
      // Creating new client
      creatingClient: false,
      newClientObj: {
        uid: '',
        name: '',
        email: '',
        password: '',
        confirmedPassword: '',
        isActive: true,
      },
      hasErrors: false,
      clientNameError: false,
      clientEmailError: false,
      clientPasswordError: false,
      clientConfirmPasswordError: false,
      passwordsMisMatch: false,
      // Viewing client
      viewClient: false,
      clientBeingViewed: {},
      updatingClient: false,
      // Updating client information
      updatingClientInformation: false,
    };
  },
  created() {
    // Set page title
    document.title = 'Client Management';
    // Grab clients available
    this.getClientsAvailable();
  },
  methods: {
    async getClientsAvailable() {
      await firebase.database().ref('clients').on('value', (snapshot) => {
        if (snapshot.exists()) {
          // Set a clear array
          this.clients = [];
          const clients = Object.entries(snapshot.val());
          clients.forEach((client) => {
            this.clients.push(client[1]);
          });
        } else {
          this.warningState = true;
          this.warningMessage = 'Currently no clients available, please create one.';
        }
      }, { onlyOnce: true });
    },
    // Creating client methods
    newClient() {
      this.creatingClient = true;
      this.newClientObj = {
        uid: '',
        name: '',
        email: '',
        password: '',
        confirmedPassword: '',
        isActive: true,
      };
    },
    async submitNewClient() {
      // Run logic on fields
      if (this.newClientObj.name === '' || this.newClientObj.email === '' || this.newClientObj.password === '' || this.newClientObj.confirmedPassword === '') {
        this.hasErrors = true;
        // Let's check the sub-items
        if (this.newClientObj.name === '') {
          this.clientNameError = true;
        }
        if (this.newClientObj.email === '') {
          this.clientEmailError = true;
        }
        if (this.newClientObj.password === '') {
          this.clientPasswordError = true;
        }
        if (this.newClientObj.confirmedPassword === '') {
          this.clientConfirmPasswordError = true;
        }
      }
      if (this.newClientObj.password !== '' && this.newClientObj.confirmedPassword !== '') {
        if (this.newClientObj.password !== this.newClientObj.confirmedPassword) {
          this.hasErrors = true;
          this.passwordsMisMatch = true;
        }
      }
      if (this.hasErrors === false) {
        try {
          // Remove confirmed password from client object
          delete this.newClientObj.confirmedPassword;
          // this.newClientObj.uid = this.generateUID();
          this.newClientObj.password = btoa(this.newClientObj.password); // Base64 encode the password for storage
          this.newClientObj.uid = firebase.database().ref('clients').push().key;
          await firebase.database().ref(`clients/${this.newClientObj.uid}`).set(this.newClientObj)
            .then((res) => {
              this.successState = true;
              this.successMessage = 'Successfully created the client.';
              // Reset the state
              this.newClientObj = {
                uid: '',
                name: '',
                email: '',
                password: '',
                confirmedPassword: '',
                isActive: true,
              };
              this.creatingClient = false;
            })
            .catch((error) => {
              if (error) {
                this.errorState = true;
                this.errorMessage = `Problem creating client. Error: ${error}`;
              }
            });
        } catch (e) {
          if (e) {
            this.errorState = true;
            this.errorMessage = `Problem creating a client. Error: ${e}`;
          }
        }
      }
    },
    viewingThisClient(client) {
      this.clientBeingViewed = client;
    },
    // Setting clients to inactive
    async setClientStatus(statusToSet) {
      if (statusToSet === 'Inactive') {
        this.clientBeingViewed.isActive = false;
      } else {
        this.clientBeingViewed.isActive = true;
      }
      try {
        const dataObj = {
          name: this.clientBeingViewed.name,
          email: this.clientBeingViewed.email,
          isActive: this.clientBeingViewed.isActive,
          password: this.clientBeingViewed.password,
          uid: this.clientBeingViewed.uid,
        };
        const updates = {};
        updates[`clients/${this.clientBeingViewed.uid}`] = dataObj;
        this.updatingClient = true;
        await firebase.database().ref().update(updates, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Problem updating client status. Error: ${error}`;
            this.updatingClient = false;
          } else {
            this.successState = true;
            this.successMessage = `Updated client status to: ${statusToSet}`;
            this.updatingClient = false;
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Problem updating client status. Error: ${e}`;
          this.updatingClient = false;
        }
      }
    },
    // Updating client informations
    async updateClientInformation() {
      if (this.clientBeingViewed.password !== '' && this.clientBeingViewed.password !== ' ') {
        this.clientBeingViewed.password = btoa(this.clientBeingViewed.password);
        try {
          this.updatingClient = true;
          const updates = {};
          updates[`clients/${this.clientBeingViewed.uid}`] = this.clientBeingViewed;
          await firebase.database().ref().update(updates, (error) => {
            if (error) {
              this.errorState = true;
              this.errorMessage = `Problem updating client information. Error: ${error}`;
              this.updatingClient = false;
            } else {
              this.successState = true;
              this.successMessage = 'Updated client information';
              this.updatingClient = false;
            }
          });
        } catch (e) {
          if (e) {
            this.errorState = true;
            this.errorMessage = `Problem updating client information. Error: ${e}`;
          }
        }
      } else {
        this.errorState = true;
        this.errorMessage = 'Client password cannot be left blank';
        console.log('Password is being left blank');
      }
    },
    // View the selected client
    viewThisClient(clientBeingViewed) {
      sessionStorage.setItem('user', JSON.stringify(clientBeingViewed));
      router.push('/admin/event-management');
    },
  },
};
</script>

<style lang="scss">
  // Color vars
  $cards-background: #FFF;
  $primary-font-color: #0B0E31;
  $button-color: #5F5DFF;

  // Font vars
  $primary-font: 'Inter', sans-serif;

  .client-page {
    min-width: 100vh;
    height: 90vh;

    padding: 0;
    margin: 0;

    background: #F5F7FB;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .container {
      width: 100%;
    }

    .actions {
      width: 100%;
      height: auto;

      h3 {
        font: 500 1.8rem $primary-font;
        color: $primary-font-color;
      }
    }

    .clients {
      width: 100%;
      height: auto;

      padding: 0;
      margin: 0;

      ul {
        width: 100%;
        height: fit-content;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: flex-start;

        .client {
          width: 100%;
          max-width: calc(100% / 4);
          height: auto;

          padding: 10px;
          background: $cards-background;
          border-radius: 5px;
          border: 1px solid #DCDBE1;

          &:nth-of-type(even) {
            margin-left: 10px;
            margin-right: 10px;
          }

          &-name {
            width: 100%;

            p {
              font: 400 1.2rem $primary-font;
              color: $primary-font-color;
            }

            .button {
              border: 1px solid lighten($button-color, 10);
              color: $button-color;

              cursor: pointer;
              transition: all .3s ease-in-out;
              &:hover, &:active {
                background-color: $button-color;
                color: $cards-background;
              }
            }
          }
          &-info {
            width: 100%;
            height: fit-content;

            svg {
              padding-right: 5px;
              path {
                fill: $button-color;
              }
            }
            p {
              font: normal 1.1rem $primary-font;
            }
          }
        }
      }
    }
  }

  .errors {
    background: #e74c3c;
    width: 100%;
    height: auto;

    padding: 15px;
    text-align: center;
    color: #FFFF;
  }
</style>
