// Import main firebase app
import firebase from 'firebase/app';
// Firebase performance monitoring
// import 'firebase/performance';
// This runs off of an env setting
const config = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: '1:386674211220:web:946e18b8afb596ee84f781', // APP ID must be static for config to work properly
};

// Init our firebase app
firebase.initializeApp(config);

// // Initialize Performance Monitoring and get a reference to the service
// firebase.performance();
