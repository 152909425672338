import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import eventManagement from '@/components/admin/eventManagement.vue';
import clientManagement from '@/components/admin/clientManagement.vue';

function checkForUser(to: any, from: any, next: any) {
  const user = firebase.auth().currentUser;
  // @ts-ignore
  const localUser = JSON.parse(sessionStorage.getItem('user'));
  if (user === null && localUser === null) {
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/login/login.vue'),
  },
  {
    path: '/admin',
    component: () => import('../components/admin/admin.vue'),
    children: [
      {
        path: 'event-management',
        component: eventManagement,
      },
      {
        path: 'client-management',
        component: clientManagement,
      },
    ],
    beforeEnter: checkForUser,
  },
  {
    path: '/events/:id',
    component: () => import('../components/event/event.vue'),
  },
  {
    path: '/event-message/:id',
    component: () => import('../components/event/eventMessage.vue'),
  },
  {
    path: '/presenter/session/:id',
    component: () => import('../components/presenter/presentor.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
