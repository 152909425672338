<template>
  <main class="events-page">
    <div class="container is-fluid">
      <div class="actions is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between">
        <div><p class="title">{{currentUser?.name || ''}} Events</p></div>
        <button class="button is-primary" v-on:click="addingEvent = true;">
          <span class="icon">
            <font-awesome-icon icon="plus" />
          </span>
          <span>Add Event</span>
        </button>
      </div>
      <!-- Event status filters -->
      <div class="upper is-flex is-align-items-center is-justify-content-space-between">
        <ul>
          <li v-bind:class="{'router-link-active': defaultFilter === 'active'}" v-on:click="defaultFilter = 'active'">
            <p>{{activeEventsAmt || ''}} Active</p>
          </li>
          <li v-bind:class="{'router-link-active': defaultFilter === 'inactive'}" v-on:click="defaultFilter = 'inactive'">
            <p>{{inactiveEventsAmt || ''}} Inactive</p>
          </li>
          <li v-bind:class="{'router-link-active': defaultFilter === 'completed'}" v-on:click=" defaultFilter = 'completed'">
            <p>{{completedEventsAmt || ''}} Completed</p>
          </li>
          <li v-bind:class="{'router-link-active': defaultFilter === 'archived'}" v-on:click="defaultFilter = 'archived'">
            <p>{{ archivedEventsAmt || ''}} Archived</p>
          </li>
          <li v-if="hasAdmin" v-bind:class="{'router-link-active': defaultFilter === 'deleted'}" v-on:click="defaultFilter = 'deleted'">
            <p>{{ deletedEventsAmt || ''}} Deleted</p>
          </li>
        </ul>
      </div>
      <div class="events-section mt-4">
        <div class="quick-add" v-if="defaultFilter === 'active' && events.length <= 0" v-on:click="addingEvent = true">
          <p>No active events. <font-awesome-icon icon="plus" /> Create an event</p>
        </div>
        <div class="quick-add" v-if="defaultFilter === 'active' && events.length >= 1 && activeEventsAmt === 0" v-on:click="addingEvent = true">
          <p>No active events. <font-awesome-icon icon="plus" /> Create an event</p>
        </div>
        <div class="events" v-if="events && events.length >= 1">
          <ul>
            <li v-for="event in events" v-bind:key="event" v-bind:class="{'hidden': event?.status !== defaultFilter}">
              <div class="event" v-if="event?.status === defaultFilter">
                <!-- Event name and actions -->
                <div class="is-flex is-justify-content-space-between is-flex-direction-row">
                  <!-- Name -->
                  <div class="event-name is-flex is-align-items-center"
                       v-bind:class="{'is-active': event?.status === 'active', 'is-info': event?.status === 'inactive', 'is-completed': event?.status === 'completed' }">
                    <font-awesome-icon class="mr-1" icon="circle" style="font-size: .8rem"></font-awesome-icon>
                    <p>{{event?.name}}</p>
                  </div>
                  <!-- Actions -->
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <!-- Admin session preview -->
                    <a class="button is-primary is-small"
                       v-if="event.sessions && event?.status === 'active' || event?.status === 'inactive'"
                       v-bind:style="{color: event.tabFontColor, background: event.tabBackground}"
                       :href="`/events/${event.eventID}?firstname=Admin&lastname=&emailaddress=Admin@go.huddlearea.com`" target="_blank"
                    >
                    <span class="icon">
                        <font-awesome-icon icon="eye"></font-awesome-icon>
                      </span>
                      <span>Preview</span>
                    </a>
                    <!-- Event settings toggle -->
                    <button class="button is-ghost is-small ml-3"
                            v-if="event?.status !== 'deleted' || event?.status !== 'archived'"
                            v-on:click="manageEvent(event)">
                    <span class="icon">
                      <font-awesome-icon icon="cogs"></font-awesome-icon>
                    </span>
                    </button>
                  </div>
                </div>
                <!-- Event sessions -->
                <div class="event-sessions">
                  <!-- No sessions -->
                  <div v-if="!event?.sessions">
                    <p class="no-session pb-2 pt-4">No sessions for this event</p>
                  </div>
                  <!-- Sessions -->
                  <div v-else style="width: 100%;">
                    <div class="upper is-flex is-align-items-flex-start is-flex-direction-column is-justify-content-space-between">
                      <p class="event-sessions-title mb-4">Sessions for this event</p>
                      <!-- Active / inactive allow dragable re-ordering -->
                      <div style="width: 100%;" v-if="event?.status === 'active'">
                        <ul class="sessions">
                          <li class="session is-flex is-justify-content-space-between is-align-items-center" v-for="session of event.sessions" v-bind:key="session">
                            <div class="session-name" v-on:click="moderateSession(session)">
                              <p>{{session?.name}}</p>
                            </div>
                            <div class="session-actions is-flex is-flex-direction-row is-align-content-center">
                              <div class="action-danger" v-on:click="removeSession(session)">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <!-- Inactive -->
                      <div style="width: 100%;" v-if="event?.status === 'inactive'">
                        <draggable
                          :list="event?.sessions"
                          :disabled="!enabled"
                          item-key="session"
                          class="sessions"
                          ghost-class="ghost"
                          @start="setEvent(event)"
                          @end="checkChanges()"
                          v-if="event?.sessions.length >= 2"
                        >
                          <template #item="{ element }">
                            <div class="session is-flex is-justify-content-space-between is-align-items-center" :class="{ 'not-draggable': !enabled }">
                              <div class="session-name" v-on:click="moderateSession(element)">
                                <p>{{element?.name}}</p>
                              </div>
                              <div class="session-actions is-flex is-flex-direction-row is-align-content-center">
                                <div class="action-danger" v-on:click="removeSession(element)">
                                  <font-awesome-icon icon="times"></font-awesome-icon>
                                </div>
                              </div>
                            </div>
                          </template>
                        </draggable>
                        <!-- One session, so no need to drag re-order -->
                        <ul class="sessions" v-else>
                          <li class="session is-flex is-justify-content-space-between is-align-items-center" v-for="session of event.sessions" v-bind:key="session">
                            <div class="session-name" v-on:click="moderateSession(session)">
                              <p>{{session?.name}}</p>
                            </div>
                            <div class="session-actions is-flex is-flex-direction-row is-align-content-center">
                              <div class="action-danger" v-on:click="removeSession(session)">
                                <font-awesome-icon icon="times"></font-awesome-icon>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <!-- Completed / Archived / Deleted -->
                      <div style="width: 100%;" v-else>
                        <ul class="sessions" v-if="event?.status === 'completed' || event?.status === 'archived'">
                          <li class="session is-flex is-justify-content-space-between is-align-items-center" v-for="session of event.sessions" v-bind:key="session">
                            <div class="session-name" v-on:click="moderateSession(session)">
                              <p>{{session?.name}}</p>
                            </div>
                          </li>
                        </ul>
                        <ul class="sessions" v-if="event?.status === 'deleted'">
                          <li class="session is-flex is-justify-content-space-between is-align-items-center" v-for="session of event.sessions" v-bind:key="session">
                            <div class="session-name" style="width: 100%; cursor: default">
                              <p>{{session?.name}}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- Adding session -->
                  <button class="button mt-2 is-fullwidth is-primary"
                          v-if="event?.status === 'active' || event?.status === 'inactive'"
                          v-on:click="addSession(event);" v-bind:style="{color: event.buttonTextColor, background: event.buttonBackground}"
                  >
                    Add Session
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
  <!-- Event management actions -->
  <div class="modal is-flex" v-if="addingEvent">
    <div class="modal-background"></div>
    <div class="modal-card is-large">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Create Event</p>
            <button class="delete" aria-label="close" v-on:click="addingEvent = false;"></button>
          </div>
        </div>
        <div class="form-section">
          <form @submit.prevent="createEvent">
            <div class="add-colors mt-5">
              <div class="is-flex is-flex-direction-row is-align-items-center">
                <p><font-awesome-icon icon="info" class="mr-2"></font-awesome-icon> Set the information of your event</p>
              </div>
            </div>
            <!-- Name and favicon -->
            <div class="field is-horizontal pt-3">
              <div class="field-body">
                <div class="field">
                  <label class="label" for="event-name">Event name</label>
                  <div class="control">
                    <input tabindex="1" class="input" id="event-name" type="text" placeholder="" v-model="newEventObj.name" required>
                  </div>
                  <p class="help is-danger" v-if="addingEventFormError === true && newEventObj.name === ''">
                    Please enter an event name
                  </p>
                </div>
                <div class="field">
                  <label class="label" for="eventFavicon">Event Favicon</label>
                  <div class="control">
                    <input tabindex="2" class="input" id="eventFavicon" type="text" placeholder="" v-model="newEventObj.favicon">
                  </div>
                </div>
              </div>
            </div>
            <!-- Font setting and presenter password -->
            <div class="field is-horizontal pt-3">
              <div class="field-body">
                <div class="field">
                  <label class="label" for="font-size">Font size <i>(px or rem)</i></label>
                  <div class="control">
                    <input tabindex="3" class="input" id="font-size" type="text" placeholder="Please enter fontsize in px format i.e 16px" v-model="newEventObj.fontSize"
                           v-bind:class="{'is-danger': addingEventFormError === true && newEventObj.fontSize === ''}"
                    >
                    <p class="help is-danger" v-if="addingEventFormError === true && newEventObj.fontSize === ''">
                      Please enter a Font Size
                    </p>
                  </div>
                </div>
                <div class="field">
                  <label class="label" for="presentor-password">Presenter password</label>
                  <div class="control">
                    <input tabindex="4" class="input" id="presentor-password" type="password" placeholder="" v-model="newEventObj.presenterPassword"
                           v-bind:class="{'is-danger':  addingEventFormError === true && newEventObj.presenterPassword === ''}"
                    >
                    <p class="help is-danger" v-if="addingEventFormError === true && newEventObj.presenterPassword === ''">
                      Please provide a presenter password
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Event color palette -->
            <div class="add-colors mt-6  pt-3 pb-3">
              <div class="is-flex is-flex-direction-row is-align-items-center">
                <p><font-awesome-icon icon="palette" class="mr-2"></font-awesome-icon> Set the color palette of your event</p>
              </div>
            </div>
            <!-- Event background -->
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                  <label class="label" for="eventBackground">Background color</label>
                  <div class="control">
                    <input tabindex="5" id="eventBackground" type="color" class="input color-picker" v-model="newEventObj.backgroundColor"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- Event tab colors -->
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                  <label class="label" for="tabBackground">Tab Background</label>
                  <p class="control">
                    <input tabindex="6" id="tabBackground" type="color" class="input color-picker" v-model="newEventObj.tabBackground" />
                  </p>
                </div>
                <div class="field">
                  <label class="label" for="tabFontColor">Tab Font Color</label>
                  <div class="control">
                    <input tabindex="7" id="tabFontColor" type="color" class="input color-picker" v-model="newEventObj.tabFontColor"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                  <label class="label" for="activeBackground">Active Background</label>
                  <div class="control">
                    <input tabindex="8" id="activeBackground" type="color" class="input color-picker" v-model="newEventObj.tabActiveBackground"/>
                  </div>
                </div>
                <div class="field">
                  <label class="label" for="activeFontColor">Active Font Color</label>
                  <div class="control">
                    <input tabindex="9" id="activeFontColor" type="color" class="input color-picker" v-model="newEventObj.tabActiveFontColor"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- Button colors -->
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                  <label class="label" for="buttonColor">Button color</label>
                  <div class="control">
                    <input tabindex="10" id="buttonColor" type="color" class="input color-picker" v-model="newEventObj.buttonBackground"/>
                  </div>
                </div>
                <div class="field" style="width: 7%;">
                  <label class="label" for="buttonFontColor">Button font color</label>
                  <div class="control">
                    <input tabindex="11" id="buttonFontColor" type="color" class="input color-picker" v-model="newEventObj.buttonTextColor"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                  <label class="label">Active button color</label>
                  <div class="control">
                    <input type="color" class="input color-picker" v-model="newEventObj.buttonActiveBackgroundColor"/>
                  </div>
                </div>
                <div class="field">
                  <label class="label">Active button font</label>
                  <div class="control">
                    <input type="color" class="input color-picker" v-model="newEventObj.buttonActiveFontColor"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="preview mt-6">
              <div class="event" v-bind:style="{background: newEventObj.backgroundColor}">
                <div class="tabs">
                  <ul>
                    <li v-bind:style="{ background: newEventObj.tabActiveBackground}">
                      <p v-bind:style="{color: newEventObj.tabActiveFontColor}">Active Tab</p>
                    </li>
                    <li v-bind:style="{ background: newEventObj.tabBackground}">
                      <p v-bind:style="{color: newEventObj.tabFontColor}">Tab</p>
                    </li>
                  </ul>
                </div>
                <div class="event-actions" v-bind:style="{background: newEventObj.backgroundColor}">
                  <div class="event-contents is-flex is-justify-content-flex-start">
                    <div class="comments-section">
                      <p>LATEST POSTS:</p>
                      <ul id="chatMessages">
                        <li>
                          <div class="comment"><p style="font-size: .9rem !important;">Commenter name: <b style="font-size: 1rem  !important;">Lorem ipsum dolor sit amet.</b></p></div>
                        </li>
                        <li>
                          <div class="comment"><p style="font-size: .9rem !important;">Commenter name: <b style="font-size: 1rem  !important;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in.</b></p></div>
                        </li>
                      </ul>
                    </div>
                    <div class="required-fields">
                      <form>
                        <!-- Name && Email -->
                        <div class="field is-horizontal">
                          <div class="field-body">
                            <div class="field">
                              <label class="label" for="comment-name">Name:</label>
                              <div class="control">
                                <input class="input" id="comment-name" type="text" placeholder="" readonly/>
                              </div>
                            </div>
                            <div class="field">
                              <label class="label" for="comment-email">Email Address:</label>
                              <div class="control">
                                <input class="input" id="comment-email" type="email" placeholder=""  readonly/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Comment -->
                        <div class="field">
                          <label class="label">Message</label>
                          <div class="is-flex is-flex-direction-row is-align-content-flex-start is-justify-content-flex-start">
                            <div class="control" style="width: 95%;">
                              <textarea class="textarea" placeholder="Shift + enter for new line" readonly></textarea>
                            </div>
                            <div class="button is-ghost" style="margin-left: 10px">
                              <span class="icon is-small">
                                <font-awesome-icon icon="smile"></font-awesome-icon>
                              </span>
                            </div>
                          </div>
                          <div class="bottom-section is-flex is-flex-direction-row is-justify-content-flex-end">
                            <!-- submit comment -->
                            <div class="field">
                              <p class="comment">
                                <a href="javascript:void(0)" class="button is-primary is-small" v-bind:style="{color:  newEventObj.buttonTextColor, background:  newEventObj.buttonBackground}">
                                  SEND  <font-awesome-icon class="ml-2" icon="long-arrow-alt-right"></font-awesome-icon>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Submit and cancel buttons section -->
            <div class="field is-grouped is-grouped-right mt-6 pt-4" style="border-top: 1px solid #cecdff">
              <div class="control">
                <a class="button is-danger is-outlined" v-on:click="addingEvent = false;">Cancel</a>
              </div>
              <div class="control">
                <button class="button is-primary" :disabled="newEventObj.name === ''">Create</button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
  <div class="modal is-flex" v-if="managingEvent">
    <div class="modal-background"></div>
    <div class="modal-card is-large">
      <section class="modal-card-body">
        <!-- Not deleting an event -->
        <div v-if="!removingEvents">
          <div class="upper">
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="title">Managing {{eventToManage.name}}</p>
              <button class="delete" aria-label="close" v-on:click="managingEvent = false;"></button>
            </div>
          </div>
          <div class="modal-actions">
            <!-- Event management setting selectors -->
            <div class="tabs is-boxed">
              <ul>
                <li v-bind:class="{'is-active': eventSettingSelected === 'status'}" v-if="eventToManage?.status !== 'deleted' || hasAdmin">
                  <a v-on:click="eventSettingSelected = 'status'">
                    <span class="icon is-small"><font-awesome-icon icon="eye"></font-awesome-icon></span>
                    <span>Status</span>
                  </a>
                </li>
                <li v-bind:class="{'is-active': eventSettingSelected === 'fonts'}" v-if="eventToManage?.status === 'inactive'">
                  <a v-on:click="eventSettingSelected = 'fonts'">
                    <span class="icon is-small"><font-awesome-icon icon="text-height"></font-awesome-icon></span>
                    <span>Font settings</span>
                  </a>
                </li>
                <li v-bind:class="{'is-active': eventSettingSelected === 'colors' }" v-if="eventToManage?.status === 'inactive'">
                  <a v-on:click="eventSettingSelected = 'colors'">
                    <span class="icon is-small"><font-awesome-icon icon="palette"></font-awesome-icon></span>
                    <span>Colors</span>
                  </a>
                </li>
                <li v-bind:class="{'is-active': eventSettingSelected === 'name'}" v-if="eventToManage?.status === 'inactive'">
                  <a v-on:click="eventSettingSelected = 'name'">
                    <span class="icon is-small"><font-awesome-icon icon="info"></font-awesome-icon></span>
                    <span>Event Name</span>
                  </a>
                </li>
                <li v-bind:class="{'is-active': eventSettingSelected === 'presenter'}" v-if="eventToManage?.status === 'inactive'">
                  <a v-on:click="eventSettingSelected = 'presenter'">
                    <span class="icon is-small"><font-awesome-icon icon="eye"></font-awesome-icon></span>
                    <span>Presenter Password</span>
                  </a>
                </li>
                <li v-bind:class="{'is-active': eventSettingSelected === 'message'}" v-if="eventToManage?.status === 'inactive'">
                  <a v-on:click="eventSettingSelected = 'message'">
                    <span class="icon is-small"><font-awesome-icon icon="user-lock"></font-awesome-icon></span>
                    <span>Locked Message</span>
                  </a>
                </li>
                <li v-bind:class="{'is-active': eventSettingSelected === 'report'}" v-if="hasAdmin && eventToManage?.status === 'completed' || eventToManage?.status === 'archived' || eventToManage?.status === 'deleted'">
                  <a v-on:click="eventSettingSelected = 'report'; getReportData(eventToManage, 'normal')">
                    <span class="icon is-small"><font-awesome-icon icon="file-alt"></font-awesome-icon></span>
                    <span>Report</span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- Selected event management section -->
            <div class="options">
              <div v-if="eventSettingSelected === 'status'">
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="eye" class="mr-2"></font-awesome-icon> Update the status of your event</p>
                  </div>
                </div>
                <div class="buttons">
                  <div v-if="eventToManage?.status === 'active'">
                    <button class="button is-link" v-on:click="updateEventStatus('inactive');">Set Inactive</button>
                    <button class="button is-info" v-on:click="updateEventStatus('completed');">Event Complete</button>
                    <button class="button is-warning" v-on:click="updateEventStatus('archived');">Archive Event</button>
                  </div>
                  <div v-if="eventToManage?.status !== 'active' && eventToManage?.status !== 'deleted'">
                    <button class="button is-success"  v-on:click="updateEventStatus('active');">Set Active</button>
                    <button class="button is-info" v-if="eventToManage?.status !== 'completed'" v-on:click="updateEventStatus('completed');">Event Complete</button>
                    <button class="button is-warning" v-on:click="updateEventStatus('archived');" v-if="eventToManage?.status !== 'archived'">Archive Event</button>
                    <button class="button is-danger" v-on:click="updateEventStatus('deleted');">Delete Event</button>
                  </div>
                  <div v-if="eventToManage?.status === 'deleted' && hasAdmin">
                    <button class="button is-link" v-on:click="updateEventStatus('inactive');">Set Inactive</button>
                  </div>
                </div>
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="pencil" class="mr-2"></font-awesome-icon> Add An Emergency Message <i class="disclaimer" style="">- Appears above video on Huddlearea.com</i></p>
                  </div>
                </div>
                <div class="field has-addons is-fullwidth">
                  <div class="control" style="width: 100%;">
                    <textarea tabindex="3" class="input" type="text" placeholder="" v-model="eventToManage.emergency" />
                  </div>
                  <div class="control" style="width: 35%">
                    <a class="button is-primary" style="width: 100%;" v-on:click="updateEventEmergencyMessage(eventToManage.emergency)">
                      Update
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="eventSettingSelected === 'fonts'">
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="text-height" class="mr-2"></font-awesome-icon> Update the font size of your session tabs</p>
                  </div>
                </div>
                <div class="field has-addons is-fullwidth">
                  <div class="control" style="width: 100%;">
                    <input tabindex="2" class="input" type="text" placeholder="Please enter fontsize in px format i.e 16px" v-model="eventToManage.fontSize">
                  </div>
                  <div class="control" style="width: 35%">
                    <a class="button is-primary" style="width: 100%;" v-on:click="updateEventFontSetting(eventToManage.fontSize)">
                      Update
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="eventSettingSelected === 'colors'">
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="palette" class="mr-2"></font-awesome-icon> Update the color palette of your event</p>
                  </div>
                </div>
                <div class="color-picking">
                  <div class="field mb-5">
                    <label class="title is-5">Background color</label>
                    <div class="control">
                      <input type="color" class="input color-picker" v-model="eventToManage.backgroundColor"/>
                    </div>
                  </div>
                  <div class="field">
                    <label class="title is-5">Tab colors</label>
                    <div class="field is-horizontal mt-3">
                      <div class="field">
                        <label class="label">Tab Background</label>
                        <p class="control">
                          <input type="color" class="input color-picker" v-model="eventToManage.tabBackground"/>
                        </p>
                      </div>
                      <div class="field">
                        <label class="label">Tab Font Color</label>
                        <p class="control">
                          <input type="color" class="input color-picker" v-model="eventToManage.tabFontColor"/>
                        </p>
                      </div>
                      <div class="field">
                        <label class="label">Tab Active Color</label>
                        <p class="control">
                          <input type="color" class="input color-picker" v-model="eventToManage.tabActiveBackground"/>
                        </p>
                      </div>
                      <div class="field">
                        <label class="label">Active Font Color</label>
                        <p class="control">
                          <input type="color" class="input color-picker" v-model="eventToManage.tabActiveFontColor"/>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label class="title is-5">Button colors</label>
                    <div class="field is-horizontal mt-3">
                      <div class="field">
                        <label class="label">Button color</label>
                        <p class="control">
                          <input type="color" class="input color-picker" v-model="eventToManage.buttonBackground"/>
                        </p>
                      </div>
                      <div class="field">
                        <label class="label">Button font color</label>
                        <p class="control">
                          <input type="color" class="input color-picker" v-model="eventToManage.buttonTextColor" />
                        </p>
                      </div>
                      <div class="field">
                        <label class="label">Active button color</label>
                        <p class="control">
                          <input type="color" class="input color-picker" v-model="eventToManage.buttonActiveBackgroundColor" />
                        </p>
                      </div>
                      <div class="field">
                        <label class="label">Active button font</label>
                        <p class="control">
                          <input type="color" class="input color-picker" v-model="eventToManage.buttonActiveFontColor"/>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="preview p-4">
                  <p class="pb-2"><b>Preview</b></p>
                  <div class="event" v-bind:style="{ background: eventToManage.backgroundColor}">
                    <div class="tabs">
                      <ul>
                        <li v-bind:style="{ background: eventToManage.tabActiveBackground}">
                          <p v-bind:style="{color: eventToManage.tabActiveFontColor}">Active Tab</p>
                        </li>
                        <li v-bind:style="{ background: eventToManage.tabBackground}">
                          <p v-bind:style="{color: eventToManage.tabFontColor}">Tab</p>
                        </li>
                      </ul>
                    </div>
                    <div class="event-actions" v-bind:style="{background: eventToManage.backgroundColor}">
                      <div class="event-contents is-flex is-justify-content-flex-start">
                        <div class="comments-section">
                          <p>LATEST POSTS:</p>
                          <ul>
                            <li>
                              <div class="comment"><p style="font-size: .9rem !important;">Commenter name: <b style="font-size: 1rem  !important;">Lorem ipsum dolor sit amet.</b></p></div>
                            </li>
                            <li>
                              <div class="comment"><p style="font-size: .9rem !important;">Commenter name: <b style="font-size: 1rem  !important;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec in.</b></p></div>
                            </li>
                          </ul>
                        </div>
                        <div class="required-fields">
                          <form>
                            <!-- Name && Email -->
                            <div class="field is-horizontal">
                              <div class="field-body">
                                <div class="field">
                                  <label class="label" for="comment-name">Name:</label>
                                  <div class="control">
                                    <input tabindex="1" class="input" type="text" placeholder="" readonly/>
                                  </div>
                                </div>
                                <div class="field">
                                  <label class="label" for="comment-email">Email Address:</label>
                                  <div class="control">
                                    <input tabindex="1" class="input" type="email" placeholder=""  readonly/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Comment -->
                            <div class="field">
                              <label class="label">Message</label>
                              <div class="is-flex is-flex-direction-row is-align-content-flex-start is-justify-content-flex-start">
                                <div class="control" style="width: 95%;">
                                  <textarea class="textarea" placeholder="Shift + enter for new line" readonly></textarea>
                                </div>
                                <div class="button is-ghost" style="margin-left: 10px">
                              <span class="icon is-small">
                                <font-awesome-icon icon="smile"></font-awesome-icon>
                              </span>
                                </div>
                              </div>
                              <div class="bottom-section is-flex is-flex-direction-row is-justify-content-flex-end">
                                <!-- submit comment -->
                                <div class="field">
                                  <p class="comment">
                                    <a href="javascript:void(0)" class="button is-primary is-small" v-bind:style="{color: eventToManage.buttonTextColor, background: eventToManage.buttonBackground}">
                                      SEND  <font-awesome-icon class="ml-2" icon="long-arrow-alt-right"></font-awesome-icon>
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-grouped is-grouped-right mt-3">
                  <p class="control">
                    <button class="button is-primary" v-on:click="updateEventColorPalette()">
                      Update
                    </button>
                  </p>
                </div>
              </div>
              <div v-if="eventSettingSelected === 'name'">
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="info" class="mr-2"></font-awesome-icon> Update the name of your event</p>
                  </div>
                </div>
                <div class="field has-addons is-fullwidth">
                  <div class="control" style="width: 100%;">
                    <input tabindex="2" class="input" type="text" placeholder="" v-model="eventToManage.name">
                  </div>
                  <div class="control" style="width: 35%">
                    <a class="button is-primary" style="width: 100%;" v-on:click="updateEventName(eventToManage.name)">
                      Update
                    </a>
                  </div>
                </div>
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="pencil" class="mr-2"></font-awesome-icon> Update tagline 1 for your event</p>
                  </div>
                </div>
                <div class="field has-addons is-fullwidth">
                  <div class="control" style="width: 100%;">
                    <textarea tabindex="3" class="input" type="text" placeholder="" v-model="eventToManage.tagline" />
                  </div>
                  <div class="control" style="width: 35%">
                    <a class="button is-primary" style="width: 100%;" v-on:click="updateEventTagline(eventToManage.tagline)">
                      Update
                    </a>
                  </div>
                </div>
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="pencil" class="mr-2"></font-awesome-icon> Update tagline 2 for your event</p>
                  </div>
                </div>
                <div class="field has-addons is-fullwidth">
                  <div class="control" style="width: 100%;">
                    <textarea tabindex="3" class="input" type="text" placeholder="" v-model="eventToManage.tagline2" />
                  </div>
                  <div class="control" style="width: 35%">
                    <a class="button is-primary" style="width: 100%;" v-on:click="updateEventTagline2(eventToManage.tagline2)">
                      Update
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="eventSettingSelected === 'presenter'">
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="eye" class="mr-2"></font-awesome-icon> Update the presenter password</p>
                  </div>
                </div>
                <div class="field has-addons is-fullwidth">
                  <div class="control" style="width: 100%;">
                    <input tabindex="2" class="input" type="password" placeholder="" v-model="eventToManage.presenterPassword">
                  </div>
                  <div class="control" style="width: 35%">
                    <a class="button is-primary" style="width: 100%;" v-on:click="updatePresentorPassword(eventToManage.presenterPassword)">
                      Update
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="eventSettingSelected === 'message'">
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="user-lock" class="mr-2"></font-awesome-icon> Manage the message for locked out users.</p>
                  </div>
                </div>
                <div class="field has-addons is-fullwidth">
                  <div class="control" style="width: 100%;">
                    <input tabindex="2" class="input" type="text" placeholder="" v-model="eventToManage.lockedMessage">
                  </div>
                  <div class="control" style="width: 35%">
                    <a class="button is-primary" style="width: 100%;" v-on:click="setLockedOutMessage(eventToManage.lockedMessage)">
                      Update
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="eventSettingSelected === 'report'">
                <div class="add-colors">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p><font-awesome-icon icon="file-alt" class="mr-2"></font-awesome-icon> Export data for this event</p>
                  </div>
                </div>
                <div class="buttons" v-if="canExport">
                  <button class="button is-info" v-on:click="exportEventData()" v-bind:class="{'is-loading': exportingData === true}">
                    Export Event Data
                  </button>
                </div>
                <div v-else>
                  <p class="title is-5 mt-5">
                    No data to export
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Deleting an event -->
        <div v-else>
          <div class="upper">
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="title">Deleting {{eventToManage.name}}</p>
              <button class="delete" aria-label="close" v-on:click="removingEvents = false;"></button>
            </div>
          </div>
          <div class="mt-3 modal-actions is-flex is-flex-direction-column is-align-items-flex-start is-align-content-center is-justify-content-center">
            <p>This action cannot be undone. This will permanently delete the {{eventToRemove?.name}} event and any sessions associated with it.</p>
            <p class="mt-2">Please type <b>events/{{eventToRemove?.name}}</b> to confirm.</p>
            <input class="input is-danger mt-2" type="text" :placeholder="'Removing ' + eventToRemove?.name" v-model="confirmEventRemovalText">
            <button class="button is-danger is-light is-fullwidth mt-4" v-if="removingEventTimer === false" :disabled="confirmEventRemovalText !== `events/${eventToRemove?.name}`" v-on:click="confirmDeletion('event')">Delete {{eventToRemove?.name}}</button>
            <button class="button is-danger is-loading is-fullwidth mt-4" v-if="removingEventTimer === true">Removing</button>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- Session management actions -->
  <div class="modal is-flex" v-if="addingSession">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Add a session</p>
            <button class="delete" aria-label="close" v-on:click="addingSession = false;"></button>
          </div>
        </div>
        <div class="form-section">
          <!-- Selecting session type -->
          <div v-if="addingSessionFormStep === 1">
            <div class="add-colors mt-2">
              <div class="is-flex is-flex-direction-row is-align-items-center">
                <p>Select an session type</p>
              </div>
            </div>
            <ul class="session-types">
              <li v-for="type in sessionTypes" v-bind:key="type" v-on:click="selectSessionType(type)">
                <p class="type-name">{{type?.name}}</p>
              </li>
            </ul>
          </div>
          <!-- Setting session options -->
          <div v-if="addingSessionFormStep === 2">
            <form @submit.prevent="createSession">
              <div class="add-colors mt-2 mb-4">
                <div class="is-flex is-flex-direction-row is-align-items-center">
                  <p><font-awesome-icon icon="chevron-circle-left" class="mr-3" v-on:click="addingSessionFormStep = 1;"></font-awesome-icon> Set the information of your session</p>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-body">
                  <div class="field">
                    <label class="label">Tab Name</label>
                    <div class="control">
                      <input class="input" type="text" placeholder="" v-model="newSessionObj.tabName">
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="newSessionObj.id !== 2">
                <div class="add-colors mt-2 mb-4">
                  <div class="is-flex is-flex-direction-row is-align-items-center">
                    <p>Set the options of your session</p>
                  </div>
                  <div class="options is-flex is-flex-wrap-wrap is-flex-direction-column is-justify-content-flex-start pt-2 mb-3">
                    <div class="is-flex is-flex-direction-row is-align-items-center is-align-content-center">
                      <!-- Setting active/inactive status -->
                      <div class="buttons mb-0 mr-3">
                        <button class="button is-warning mb-0"  v-if="newSessionObj.status === 'active'" v-on:click="newSessionObj.status = 'inactive'">Set to inactive</button>
                        <button class="button is-success mb-0" v-if="newSessionObj.status !== 'active'"  v-on:click="newSessionObj.status = 'active'">Set to active</button>
                      </div>
                      <div class="fields">
                        <div class="control">
                          <div class="button" style="padding-right: 10px; padding-left: 0;">
                            <input type="checkbox" class="cm-toggle" v-model="newSessionObj.moderation">Moderate</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="is-flex is-flex-direction-row is-align-items-center is-align-content-center mt-3">
                    <div class="fields mr-2">
                      <div class="control">
                        <div class="button">
                          <input type="checkbox" class="cm-toggle" v-model="newSessionObj.protected" v-on:click="newSessionObj.register = false; newSessionObj.anonymous = false">Protected</div>
                      </div>
                    </div>
                    <div class="fields mr-2">
                      <div class="control">
                        <div class="button">
                          <input type="checkbox" class="cm-toggle" v-model="newSessionObj.register" v-on:click="newSessionObj.protected = false; newSessionObj.anonymous = false">Register</div>
                      </div>
                    </div>
                    <div class="fields mr-2">
                      <div class="control">
                        <div class="button">
                          <input type="checkbox" class="cm-toggle" v-model="newSessionObj.anonymous" v-on:click="newSessionObj.protected = false; newSessionObj.register = false">Anonymous</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Creating the event -->
              <div class="field is-grouped is-grouped-right pt-3" style="border-top: 1px solid #cecdff">
                <div class="control">
                  <a class="button is-danger is-outlined" v-on:click="addingSessionFormStep = 1;">Cancel</a>
                </div>
                <div class="control">
                  <button class="button is-primary">Create</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="modal is-flex" v-if="moderatingSessions">
    <div class="modal-background"></div>
    <div class="modal-card is-large">
      <section class="modal-card-body">
        <div class="upper">
          <div class="card-statuses pb-2 is-flex is-flex-direction-row">
            <!-- Session statuses -->
            <span class="tag is-active is-medium" v-if="sessionToModerate?.status === 'active'">{{sessionToModerate.status}}</span>
            <span class="tag not-active is-medium" v-if="sessionToModerate?.status === 'inactive'">{{sessionToModerate.status}}</span>
            <!-- Session moderation -->
            <span class="tag moderated is-medium ml-3" v-if="sessionToModerate?.moderation">
              <font-awesome-icon class="mr-2" icon="eye"></font-awesome-icon>
              Session Moderated</span>
            <!-- Session settings -->
            <span class="tag protected is-medium ml-3" v-if="sessionToModerate?.protected">
              <font-awesome-icon class="mr-2" icon="user-lock"></font-awesome-icon>
              Password Protected</span>
            <span class="tag protected is-medium ml-3" v-if="sessionToModerate?.register">
              <font-awesome-icon class="mr-2" icon="user-plus"></font-awesome-icon>
             User Registration
            </span>
            <span class="tag protected is-medium ml-3" v-if="sessionToModerate?.anonymous">
              <font-awesome-icon class="mr-2" icon="user-astronaut"></font-awesome-icon>
              Anonymous User
            </span>
          </div>
          <div class="session-settings is-flex is-align-items-center is-justify-content-space-between is-align-content-center mt-3">
            <p class="title">
              {{sessionToModerate?.eventName}} - {{sessionToModerate?.tabName}} session
            </p>
            <div class="actions">
              <a v-bind:href="'/presenter/session/' + sessionToModerate?.sessionID" target="_blank" class="button is-primary is-small mr-2" v-if="sessionToModerate?.id !== 2 && sessionSettings === false">
                <span class="icon">
                  <font-awesome-icon icon="book-reader"></font-awesome-icon>
                </span>
                <span>Presenter view</span>
              </a>
              <button class="button is-primary is-small mr-2" v-if="sessionSettings === false" v-on:click="sessionSettings = true">
                <span class="icon">
                  <font-awesome-icon icon="cogs"></font-awesome-icon>
                </span>
                <span>Settings</span>
              </button>
              <button class="button is-primary is-small mr-2" v-if="sessionSettings === true" v-on:click="sessionSettings = false">
                <span class="icon">
                  <font-awesome-icon icon="times"></font-awesome-icon>
                </span>
                <span>Close Settings</span>
              </button>
              <button class="delete mt-1 ml-3" aria-label="close" v-on:click="moderatingSessions = false; stopModeration(sessionToModerate); sessionSettings = false;"></button>
            </div>
          </div>
        </div>
        <div class="break mt-4"></div>
        <div class="modal-settings" v-if="sessionSettings">
          <div class="intro mb-4">
            <p>Select Options below</p>
          </div>
          <div class="setting" v-if="sessionToModerate?.status === 'inactive'">
            <label class="label">Update session name</label>
            <div class="field has-addons is-fullwidth">
              <div class="control" style="width: 100%;">
                <input tabindex="2" class="input" type="text" placeholder="{{sessionToModerate.tabName}}" v-model="sessionToModerate.tabName">
              </div>
              <div class="control" style="width: 35%">
                <a class="button is-primary" style="width: 100%;" v-on:click="updateSessionName(sessionToModerate.tabName)">
                  Update
                </a>
              </div>
            </div>
          </div>
          <div class="setting mt-2" v-if="sessionToModerate?.id === 0">
            <div class="pinned-message">
              <label class="label">Pinned message</label>
              <div class="field has-addons is-fullwidth">
                <div class="control" style="width: 100%;">
                  <input tabindex="2" class="input" type="text" placeholder="" v-model="sessionToModerate.pinnedMessage">
                </div>
                <div class="control" style="width: 35%">
                  <a class="button is-primary" style="width: 100%;" v-on:click="setPinnedMessage(sessionToModerate?.pinnedMessage)">
                    Update
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="setting mt-4">
            <label class="label">Update session settings</label>
            <div class="options is-flex is-flex-wrap-wrap is-flex-direction-column is-justify-content-flex-start">
              <div class="is-flex is-flex-direction-row is-align-items-center is-align-content-center">
                <!-- Setting active/inactive status -->
                <!-- Active -->
                <div class="field mr-2" v-if="sessionToModerate.status === 'active'">
                  <div class="control">
                    <button class="button is-warning" v-on:click="sessionToModerate.status = 'inactive'; updateSessionSetting()">Set to inactive</button>
                  </div>
                </div>
                <!-- Inactive -->
                <div class="field mr-2" v-if="sessionToModerate.status === 'inactive' || sessionToModerate?.status === ''">
                  <div class="control">
                    <button class="button is-success" v-on:click="sessionToModerate.status = 'active'; updateSessionSetting()">Set to active</button>
                  </div>
                </div>
                <!-- Event flags -->
                <div class="fields" v-if="sessionToModerate?.id !== 2">
                  <div class="control">
                    <div class="button" style="padding-right: 10px; padding-left: 0;">
                      <input type="checkbox" class="cm-toggle" v-model="sessionToModerate.moderation" v-on:change="updateSessionSetting()">Moderate</div>
                  </div>
                </div>
              </div>
              <div class="is-flex is-flex-direction-row is-align-items-center is-align-content-center mt-3" v-if="sessionToModerate?.status === 'inactive'">
                <div class="fields mr-2" >
                  <div class="control">
                    <div class="button" style="padding-right: 10px; padding-left: 0;">
                      <input type="checkbox" class="cm-toggle"
                             v-model="sessionToModerate.protected"
                             v-on:change="sessionToModerate.register = false; sessionToModerate.anonymous = false; updateSessionSetting()">Protected</div>
                  </div>
                </div>
                <div class="fields mr-2" v-if="sessionToModerate?.id !== 2">
                  <div class="control">
                    <div class="button" style="padding-right: 10px; padding-left: 0;">
                      <input type="checkbox" class="cm-toggle" v-model="sessionToModerate.register" v-on:change="sessionToModerate.protected = false; sessionToModerate.anonymous = false; updateSessionSetting()">Register</div>
                  </div>
                </div>
                <div class="fields mr-2" v-if="sessionToModerate?.id !== 2">
                  <div class="control">
                    <div class="button" style="padding-right: 10px; padding-left: 0;">
                      <input type="checkbox" class="cm-toggle" v-model="sessionToModerate.anonymous" v-on:change="sessionToModerate.protected = false; sessionToModerate.register = false; updateSessionSetting()">Anonymous</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Live chat / QA session type -->
        <!-- Live chat -->
        <div class="modal-content" v-if="sessionToModerate.id === 0">
          <div class="no-data" v-if="liveChats.length <= 0">
            <p class="title is-3">No data available</p>
            <p class="subtitle is-5">Please check back once the event starts</p>
          </div>
          <div v-else>
            <div class="content-filters">
              <div class="tabs">
                <ul v-if="sessionToModerate.id === 0">
                  <li v-bind:class="{'is-active': currentContentType === 'approved'}" v-on:click="currentContentType = 'approved'"><a>{{chatsactiveAmount}} Approved</a></li>
                  <li v-bind:class="{'is-active': currentContentType === 'pending'}" v-on:click="currentContentType = 'pending'"><a>{{chatspendingAmount}} Pending</a></li>
                  <li v-bind:class="{'is-active': currentContentType === 'deleted'}" v-on:click="currentContentType = 'deleted'"><a>{{chatsdeletedAmount}} Deleted/Removed</a></li>
                </ul>
              </div>
            </div>
            <div class="comments">
              <ul class="is-flex is-flex-direction-column-reverse pb-4" v-if="sessionToModerate?.id === 0">
                <li class="is-fullwidth mb-3" v-for="content of liveChats" :key="content" v-bind:class="{'hidden': content?.status !== currentContentType}">
                  <div class="comment" v-if="content?.status === currentContentType">
                    <div class="comment-contents">
                      <div class="new-status" v-if="content?.isNew === true"></div>
                      <p class="comment-name">{{content?.name}}</p>
                      <p class="comment-content" v-if="currentContentType !== 'pending'">{{content?.comment}}</p>
                      <textarea tabindex="1" class="input" id="comment-content" type="text" v-if="currentContentType === 'pending'" v-model="content.comment" v-on:blur="sessionContentChatModeration(content)"></textarea>
                    </div>
                    <div class="comment-actions" v-if="sessionToModerate?.status === 'active'">
                      <!-- Is active -->
                      <div class="buttons" v-if="currentContentType === 'approved'">
                        <button class="button" v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-down"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Pending -->
                      <div class="buttons" v-if="currentContentType === 'pending'">
                        <button class="button" v-on:click="content.status = 'approved'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-up"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Deleted / Removed -->
                      <div class="buttons" v-if="currentContentType === 'deleted'">
                        <button class="button"  v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-up"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                    </div>
                    <div class="comment-actions" v-if="sessionToModerate?.status === 'inactive'">
                      <!-- Is pinned -->
                      <div class="buttons" v-if="currentContentType === 'pinned'">
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Is active -->
                      <div class="buttons" v-if="currentContentType === 'approved'">
                        <button class="button" v-if="content?.name === 'Admin' && content?.email === 'Admin@go.hudellarea.com' && sessionToModerate?.id === 0" v-on:click="content.status = 'pinned'; sessionContentModeration(content)">
                          <span class="icon is-small">
                          <font-awesome-icon icon="thumbtack"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-down"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Pending -->
                      <div class="buttons" v-if="currentContentType === 'pending'">
                        <button class="button" v-on:click="content.status = 'approved'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-up"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Deleted / Removed -->
                      <div class="buttons" v-if="currentContentType === 'deleted'">
                        <button class="button"  v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-up"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-content" v-if="sessionToModerate.id === 1">
          <div class="no-data" v-if="questions.length <= 0">
            <p class="title is-3">No data available</p>
            <p class="subtitle is-5">Please check back once the event starts</p>
          </div>
          <div v-else>
            <div class="content-filters">
              <div class="tabs">
                <ul v-if="sessionToModerate.id === 1">
                  <li v-bind:class="{'is-active': currentContentType === 'pinned'}" v-on:click="currentContentType = 'pinned'"><a>{{questionspinnedAmount}} Pinned</a></li>
                  <li v-bind:class="{'is-active': currentContentType === 'approved'}" v-on:click="currentContentType = 'approved'"><a>{{questionsactiveAmount}} Approved</a></li>
                  <li v-bind:class="{'is-active': currentContentType === 'pending'}" v-on:click="currentContentType = 'pending'"><a>{{questionspendingAmount}} Pending</a></li>
                  <li v-bind:class="{'is-active': currentContentType === 'deleted'}" v-on:click="currentContentType = 'deleted'"><a>{{questionsdeletedAmount}} Deleted/Removed</a></li>
                </ul>
              </div>
            </div>
            <div class="comments" >
              <ul class="is-flex is-flex-direction-column-reverse pb-4" v-if="sessionToModerate?.id === 1">
                <li class="is-fullwidth mb-3" v-for="content of questions" :key="content" v-bind:class="{'hidden': content?.status !== currentContentType}">
                  <div class="comment" v-if="content?.status === currentContentType">
                    <div class="comment-contents">
                      <div class="new-status" v-if="content?.isNew === true"></div>
                      <p class="comment-name">{{content?.name}}</p>
                      <p class="comment-content" v-if="currentContentType !== 'pending'">{{content?.comment}}</p>
                      <textarea tabindex="1" class="input" id="comment-content" type="text" v-if="currentContentType === 'pending'" v-model="content.comment" v-on:blur="sessionContentCommentModeration(content)"></textarea>
                    </div>
                    <div class="comment-actions" v-if="sessionToModerate?.status === 'active'">
                      <!-- Is pinned -->
                      <div class="buttons" v-if="currentContentType === 'pinned'">
                        <button class="button" v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Is active -->
                      <div class="buttons" v-if="currentContentType === 'approved'">
                        <button class="button" v-if="sessionToModerate?.id === 0 && content?.email === 'Admin@go.huddlearea.com'" v-on:click="content.status = 'pinned'; sessionContentModeration(content)">
                          <span class="icon is-small">
                          <font-awesome-icon icon="thumbtack"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-down"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Pending -->
                      <div class="buttons" v-if="currentContentType === 'pending'">
                        <button class="button" v-on:click="content.status = 'approved'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-up"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Deleted / Removed -->
                      <div class="buttons" v-if="currentContentType === 'deleted'">
                        <button class="button"  v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-up"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                    </div>
                    <div class="comment-actions" v-if="sessionToModerate?.status === 'inactive'">
                      <!-- Is pinned -->
                      <div class="buttons" v-if="currentContentType === 'pinned'">
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Is active -->
                      <div class="buttons" v-if="currentContentType === 'approved'">
                        <button class="button" v-if="content?.name === 'Admin' && content?.email === 'Admin@go.hudellarea.com' && sessionToModerate?.id === 0" v-on:click="content.status = 'pinned'; sessionContentModeration(content)">
                          <span class="icon is-small">
                          <font-awesome-icon icon="thumbtack"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-down"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Pending -->
                      <div class="buttons" v-if="currentContentType === 'pending'">
                        <button class="button" v-on:click="content.status = 'approved'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-up"></font-awesome-icon>
                        </span>
                        </button>
                        <button class="button" v-on:click="content.status = 'deleted'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                      <!-- Deleted / Removed -->
                      <div class="buttons" v-if="currentContentType === 'deleted'">
                        <button class="button"  v-on:click="content.status = 'pending'; sessionContentModeration(content)">
                        <span class="icon is-small">
                          <font-awesome-icon icon="chevron-circle-up"></font-awesome-icon>
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Dynamic content session type -->
        <div v-if="sessionToModerate.id === 2">
          <div class="add" v-on:click="showContentAdd = !showContentAdd">
            <p>Add Dynamic content</p>
          </div>
          <div class="add-content" v-if="showContentAdd">
            <p class="title is-5" style="margin-bottom: 0">Add a content type to your feed</p>
            <p class="is-small" style="font-size: .8rem; font-style: italic;"><b>*Note: if embedding a website or image and you get an error on your dynamic feed such as url refused to connect. Please remove that content as it will not display.</b></p>
            <div class="content-form">
              <form @submit.prevent="addDynamicContent">
                <div class="field is-horizontal">
                  <div class="field-body">
                    <div class="field">
                      <label class="label">Media name</label>
                      <p class="control is-expanded">
                        <input class="input" type="text" placeholder="" v-model="contentEmbed.name">
                      </p>
                    </div>
                    <div class="field" style="width: 50%;">
                      <label class="label">Media URL</label>
                      <p class="control is-expanded">
                        <input class="input" type="text" placeholder="" v-model="contentEmbed.url">
                      </p>
                    </div>
                    <div class="field" style="margin-top: 40px">
                      <label class="checkbox">
                        <input type="checkbox" v-model="contentEmbed.fileDownload">
                        File Download
                      </label>
                    </div>
                  </div>
                </div>
                <button class="button is-primary is-fullwidth" :disabled="contentEmbed.url === ''" v-bind:class="{'is-loading': addingContent === true}">Add Content</button>
              </form>
            </div>
          </div>
          <div class="content-filters mt-3">
            <div class="tabs">
              <ul>
                <li v-bind:class="{'is-active': currentContentType === 'approved'}" v-on:click="currentContentType = 'approved'"><a>{{activeAmount || ''}} Approved</a></li>
                <li v-bind:class="{'is-active': currentContentType === 'deleted'}" v-on:click="currentContentType = 'deleted'"><a>{{deletedAmount || ''}} Deleted/Removed</a></li>
              </ul>
            </div>
          </div>
          <div class="comments">
            <ul class="table-display">
              <li>
                <p>Media Name</p>
                <p>Media URL</p>
                <p>Preview</p>
                <p v-if="currentContentType === 'approved'">Remove</p>
                <p v-if="currentContentType === 'deleted'">Approve</p>
              </li>
              <li v-for="content of sessionContents" :key="content" class="pt-3">
                <div v-if="content?.status === currentContentType" class="is-flex is-flex-direction-row is-justify-content-space-between" style="width: 100%;">
                  <p>{{content?.name || 'N/A'}}</p>
                  <p class="overflow"><font-awesome-icon icon="download" v-if="content?.fileDownload"></font-awesome-icon> {{content?.url}}</p>
                  <p><a :href="content?.url" target="_blank"><font-awesome-icon icon="eye"></font-awesome-icon></a></p>
                  <p v-if="currentContentType === 'approved'"><a v-on:click="content.status = 'deleted'; sessionContentModeration(content)"><font-awesome-icon icon="eye-slash"></font-awesome-icon></a></p>
                  <p v-if="currentContentType === 'deleted'"><a v-on:click="content.status = 'approved'; sessionContentModeration(content)"><font-awesome-icon icon="thumbs-up"></font-awesome-icon></a></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="modal is-flex" v-if="removingSessions">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between is-align-content-center">
            <p class="title">
              Remove session
            </p>
            <div class="actions">
              <button class="delete mt-1 ml-3" aria-label="close" v-on:click="removingSessions = false;"></button>
            </div>
          </div>
        </div>
        <div class="modal-content">
          <p>This action cannot be undone. This will permanently delete the {{sessionToRemove?.name}} session.</p>
          <p class="mt-2">Please type <b>session/{{sessionToRemove?.name}}</b> to confirm.</p>
          <input class="input is-danger mt-2" type="text" placeholder="" v-model="confirmSessionRemovalText">
          <button class="button is-danger is-light is-fullwidth mt-4" :disabled="confirmSessionRemovalText !== `session/${sessionToRemove?.name}`" v-on:click="confirmSessionRemoval">I understand the consequences, delete {{sessionToRemove?.name}}</button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// Firebase imports
import firebase from 'firebase/app';
// Database
import 'firebase/database';

// Data reporting service
import { exportExcel } from '@/services/exporting_excel';

// Third party
import draggable from 'vuedraggable';

export default {
  name: 'eventManagement',
  components: {
    draggable,
  },
  data() {
    return {
      // App states
      errorState: false,
      errorMessage: '',
      warningState: false,
      warningMessage: '',
      successState: false,
      successMessage: '',
      // Logged in user
      currentUser: {},
      hasAdmin: false,
      currentAdmin: {},
      // Data objects
      events: [],
      // Adding event
      addingEvent: false,
      newEventObj: {
        eventID: '',
        clientID: '',
        name: '',
        favicon: '',
        presenterPassword: '',
        lockedMessage: '',
        fontSize: '',
        backgroundColor: '#E7EAE6',
        tabBackground: '#BF1F33',
        tabFontColor: '#FFFFFF',
        tabActiveBackground: '#7C1023',
        tabActiveFontColor: '#FDB64F',
        chatMessageBackground: '#7C1023',
        chatTextBackground: '#FFFFFF',
        buttonBackground: '#E77F33',
        buttonTextColor: '#FDF8F5',
        buttonActiveBackgroundColor: '#FDB64F',
        buttonActiveFontColor: '#DFDFDF',
        isDefault: false,
        status: 'active',
      },
      // Adding Event form logic
      addingEventFormError: false,
      currentlyAddingEvent: false,
      // Event filters
      defaultFilter: '',
      // Adding session
      sessionTypes: [
        {
          id: 0,
          type: 'chat',
          name: 'Chat',
        },
        {
          id: 1,
          type: 'questions',
          name: 'Q + A',
        },
        {
          id: 2,
          type: 'content',
          name: 'More',
        },
      ],
      addingSession: false,
      addingSessionFormStep: 1,
      newSessionObj: {
        sessionID: '',
        clientID: '',
        eventID: '',
        eventName: '',
        orderID: 0,
        id: 0,
        type: '',
        name: '',
        tabName: '',
        status: 'active',
        pinnedMessage: '',
        anonymous: false,
        fields: false,
        moderation: false,
        protected: true,
        register: false,
        userInfo: false,
      },
      // Event management
      managingEvent: false,
      eventToManage: {},
      eventSettingSelected: 'status',
      // Event Data exporting
      canExport: false,
      exportingData: false,
      reportingError: false,
      fileName: '',
      dataToExport: [],
      reportingErrorMessage: '',
      reportError: [],
      // Event amounts
      activeEventsAmt: 0,
      inactiveEventsAmt: 0,
      completedEventsAmt: 0,
      archivedEventsAmt: 0,
      deletedEventsAmt: 0,
      // Session moderation
      moderatingSessions: false,
      sessionToModerate: {},
      sessionSettings: false,
      // Session contents
      sessionContents: [],
      liveChats: [],
      questions: [],
      currentContentType: '',
      chatspinnedAmount: 0,
      chatsactiveAmount: 0,
      chatsupcomingAmount: 0,
      chatspendingAmount: 0,
      chatsansweredAmount: 0,
      chatsdeletedAmount: 0,
      questionspinnedAmount: 0,
      questionsactiveAmount: 0,
      questionsupcomingAmount: 0,
      questionspendingAmount: 0,
      questionsansweredAmount: 0,
      questionsdeletedAmount: 0,
      pinnedAmount: 0,
      activeAmount: 0,
      upcomingAmount: 0,
      pendingAmount: 0,
      answeredAmount: 0,
      deletedAmount: 0,
      // Dynamic content
      showContentAdd: false,
      addingContent: false,
      downloadLink: false,
      contentEmbed: {
        key: '',
        name: '',
        url: '',
        status: 'approved',
        contentType: '',
        fileDownload: false,
        date: '',
      },
      // Removing events
      removingEvents: false,
      eventToRemove: {},
      confirmEventRemovalText: '',
      removingEventTimer: false,
      // Removing sessions
      removingSessions: false,
      sessionToRemove: {},
      confirmSessionRemovalText: '',
      // Draggable
      draggableEventID: '',
      draggableEvent: {},
      enabled: true,
      dragging: false,
    };
  },
  created() {
    // Get base user
    this.currentUser = JSON.parse(sessionStorage.getItem('user'));
    delete this.currentUser.password;
    delete this.currentUser.isActive;
    this.getEventsAvailable();
    this.defaultFilter = 'active';
    // Get admin if avail
    const adminCheck = JSON.parse(sessionStorage.getItem('admin'));
    if (adminCheck !== null) {
      document.title = 'Admin | Event Management';
      this.hasAdmin = true;
    }
    if (adminCheck === null) {
      document.title = `${this.currentUser?.name} events`;
      this.hasAdmin = false;
    }
  },
  methods: {
    // Events
    async getEventsAvailable() {
      const eventsRef = firebase.database().ref('events');
      eventsRef.on('value', (snapshot) => {
        if (snapshot.exists()) {
          this.events = [];
          const events = Object.values(snapshot.val());
          events.forEach((event) => {
            if (this.currentUser.uid === event.uid || this.currentUser.uid === event.clientID) {
              if (event.sessions) {
                // eslint-disable-next-line no-param-reassign
                event.sessions = Object.values(event.sessions);
                event.sessions.sort((a, b) => a.orderID - b.orderID);
              }
              this.events.push(event);
            }
          });
          this.activeEventsAmt = this.events.filter((item) => item.status === 'active').length;
          this.inactiveEventsAmt = this.events.filter((item) => item.status === 'inactive').length;
          this.completedEventsAmt = this.events.filter((item) => item.status === 'completed').length;
          this.archivedEventsAmt = this.events.filter((item) => item.status === 'archived').length;
          this.deletedEventsAmt = this.events.filter((item) => item.status === 'deleted').length;
        }
      });
    },
    async createEvent() {
      this.newEventObj.clientID = this.currentUser.uid;
      this.newEventObj.presenterPassword = btoa(this.newEventObj.presenterPassword);
      try {
        this.newEventObj.eventID = firebase.database().ref('events').push().key;
        setTimeout(() => {
          firebase.database().ref(`events/${this.newEventObj.eventID}`).set(this.newEventObj)
            .then(() => {
              this.successState = true;
              this.successMessage = 'Event created';
              // Reset the state
              this.addingEvent = false;
            })
            .catch((error) => {
              if (error) {
                this.errorState = true;
                this.errorMessage = `Problem creating event. Error: ${error}`;
              }
            });
        }, 200);
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Problem creating event. Error: ${e}`;
        }
      }
    },
    manageEvent(eventToManage) {
      this.managingEvent = true;
      this.eventToManage = eventToManage;
      // Set default status
      if (eventToManage.status !== 'deleted') {
        this.eventSettingSelected = 'status';
      }
    },
    // Event management settings
    async updateEventStatus(newStatus) {
      try {
        if (newStatus !== 'deleted') {
          const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
          await eventRef.update({
            status: newStatus,
          }, (error) => {
            if (error) {
              this.errorState = true;
              this.errorMessage = `Error updating the event status. ${error}`;
            } else {
              this.successState = true;
              this.successMessage = `${this.eventToManage.name} status updated.`;
              this.managingEvent = false;
            }
          });
          // Check for sessions and loop over to set status to new status
          if (this.eventToManage.sessions) {
            const sessions = Object.values(this.eventToManage.sessions);
            await Promise.all(sessions.map(async (session) => {
              await firebase.database().ref(`sessions/${session.sessionID}`)
                .update({
                  status: newStatus,
                }, (error) => {
                  console.error(`Error updating ${session} status to ${newStatus}. ${error}`);
                });
            }));
          } else {
            console.info('No sessions to update the status');
          }
        } else {
          this.removingEvents = true;
          this.eventToRemove = this.eventToManage;
        }
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating event status. ${e}`;
        }
      }
    },
    // font settings
    async updateEventFontSetting(newSize) {
      // const eventID = this.eventToManage.dbID !== undefined ? this.eventToManage.dbID : this.eventToManage.uid;
      try {
        const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
        await eventRef.update({
          fontSize: newSize,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating event font size. ${error}`;
          } else {
            this.successState = true;
            this.successMessage = `${this.eventToManage.name} font size updated.`;
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the event font size. ${e}`;
        }
      }
    },
    // Updating color palette
    async updateEventColorPalette() {
      try {
        const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
        await eventRef.update({
          backgroundColor: this.eventToManage.backgroundColor,
          buttonActiveBackgroundColor: this.eventToManage.buttonActiveBackgroundColor,
          buttonActiveFontColor: this.eventToManage.buttonActiveFontColor,
          buttonBackground: this.eventToManage.buttonBackground,
          buttonTextColor: this.eventToManage.buttonTextColor,
          chatMessageBackground: this.eventToManage.chatMessageBackground,
          chatTextBackground: this.eventToManage.chatTextBackground,
          tabActiveBackground: this.eventToManage.tabActiveBackground,
          tabActiveFontColor: this.eventToManage.tabActiveFontColor,
          tabBackground: this.eventToManage.tabBackground,
          tabFontColor: this.eventToManage.tabFontColor,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating the event color palette. ${error}`;
          } else {
            this.successState = true;
            this.successMessage = `${this.eventToManage.name} color palette updated.`;
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the event color palette. ${e}`;
        }
      }
    },
    // Updating event name
    async updateEventName(nameToUpdateTo) {
      try {
        // Update event name
        const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
        await eventRef.update({
          name: nameToUpdateTo,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating the ${this.eventToManage.name} name. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
        // Update session names to new event name
        if (this.eventToManage.sessions) {
          const sessionsInEvent = Object.entries(this.eventToManage.sessions);
          sessionsInEvent.forEach((session) => {
            // eslint-disable-next-line no-param-reassign
            session[1].name = nameToUpdateTo;
            const sessionInEventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}/sessions/${session[0]}`);
            sessionInEventRef.update({
              name: nameToUpdateTo,
            }, (error) => {
              if (error) {
                this.errorState = true;
                this.errorMessage = `Error updating the ${this.eventToManage.name} name. ${error}`;
              } else {
                // TODO: Add success message
              }
            });
            // Update the name of the sessions in the session specific table.
            const sessionRef = firebase.database().ref(`sessions/${session[0]}`);
            sessionRef.update({
              eventName: nameToUpdateTo,
              // eslint-disable-next-line no-shadow
            }, (error) => {
              if (error) {
                this.errorState = true;
                this.errorMessage = `Error updating the ${this.eventToManage.name} name. ${error}`;
              } else {
                // TODO: Add success message
              }
            });
          });
        }
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the event name. ${e}`;
        }
      }
    },
    // Updating event tagline 1
    async updateEventTagline(taglineToUpdateTo) {
      try {
        // Update event tagline
        const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
        await eventRef.update({
          tagline: taglineToUpdateTo,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating the ${this.eventToManage.tagline} tagline. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the event tagline. ${e}`;
        }
      }
    },
    // Updating event tagline 2
    async updateEventTagline2(taglineToUpdateTo) {
      try {
        // Update event tagline
        const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
        await eventRef.update({
          tagline2: taglineToUpdateTo,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating the ${this.eventToManage.tagline2} tagline2. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the event tagline2. ${e}`;
        }
      }
    },
    // Updating event emergency message
    async updateEventEmergencyMessage(emergencyMessageToUpdateTo) {
      try {
        // Update event emergency
        const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
        await eventRef.update({
          emergency: emergencyMessageToUpdateTo,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating the ${this.eventToManage.emergency} emergency. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the event emergency. ${e}`;
        }
      }
    },
    // Updating the presenter password
    async updatePresentorPassword(passwordToUpdate) {
      try {
        const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
        await eventRef.update({
          presenterPassword: btoa(passwordToUpdate),
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating the presentor password. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the presenter password. ${e}`;
        }
      }
    },
    async setLockedOutMessage(message) {
      try {
        const eventRef = firebase.database().ref(`events/${this.eventToManage?.eventID}`);
        await eventRef.update({
          lockedMessage: message.trim(),
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating the locked out message. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the locked out message. ${e}`;
        }
      }
    },
    // Deletion of event
    async confirmDeletion(type) {
      const eventID = this.eventToManage?.eventID;
      try {
        if (type === 'event') {
          this.removingEventTimer = true;
          const eventRef = firebase.database().ref(`events/${eventID}`);
          await eventRef.update({
            status: 'deleted',
          }, (error) => {
            if (error) {
              this.errorState = true;
              this.errorMessage = `Error updating the event status. ${error}`;
            } else {
              this.managingEvent = false;
              this.removingEventTimer = false;
              this.removingEvents = false;
              this.successState = true;
              this.successMessage = `${this.eventToManage.name} removed`;
            }
          });
          // Check for sessions and loop over to set status to deleted
          if (this.eventToManage.sessions) {
            const sessions = Object.values(this.eventToManage.sessions);
            await Promise.all(sessions.map(async (session) => {
              await firebase.database().ref(`sessions/${session.sessionID}`)
                .update({
                  status: 'deleted',
                }, (error) => {
                  console.error(`Error updating ${session} status to deleted. ${error}`);
                });
            }));
            this.getReportData(this.eventToManage, 'delete');
          } else {
            console.info('No sessions to update the status');
          }
        }
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error removing item. ${e}`;
        }
      }
    },
    // Session logic
    // Adding sessions
    addSession(eventAddingTo) {
      this.newSessionObj.clientID = eventAddingTo.clientID;
      this.newSessionObj.eventID = eventAddingTo.eventID;
      this.newSessionObj.eventName = eventAddingTo.name;
      this.addingSession = !this.addingSession;
    },
    selectSessionType(selectedSessionType) {
      this.newSessionObj.id = selectedSessionType.id;
      this.newSessionObj.type = selectedSessionType.type;
      this.newSessionObj.name = selectedSessionType.name;
      this.addingSessionFormStep = 2;
    },
    async createSession() {
      // Checking for empty tab name
      if (this.newSessionObj.tabName === '') {
        this.newSessionObj.tabName = `${this.newSessionObj.name}`;
      }
      try {
        // Let's grab sessions for this event
        const eventRef = firebase.database().ref(`events/${this.newSessionObj.eventID}`);
        eventRef.on('value', (snap) => {
          if (snap.exists()) {
            const eventObj = snap.val();
            if (eventObj.sessions === null || eventObj.sessions === [] || eventObj.sessions === undefined) {
              this.newSessionObj.orderID = 0;
            } else {
              // Get last item in array for grabbing their order id
              const sessions = Object.values(eventObj.sessions);
              const lastElement = sessions[sessions.length - 1];
              this.newSessionObj.orderID = lastElement.orderID + 1;
            }
          } else {
            console.info('Event does not exist in firebase');
          }
        });
        setTimeout(async () => {
          this.newSessionObj.sessionID = firebase.database().ref('sessions').push().key;
          this.newSessionObj.questions = ['Initializing Questions'];
          const inserts = {};
          inserts[`sessions/${this.newSessionObj.sessionID}`] = this.newSessionObj;
          inserts[`events/${this.newSessionObj.eventID}/sessions/${this.newSessionObj.sessionID}`] = {
            eventID: this.newSessionObj.eventID,
            sessionID: this.newSessionObj.sessionID,
            name: this.newSessionObj.tabName,
            type: this.newSessionObj.type,
            orderID: this.newSessionObj.orderID,
          };
          await firebase.database().ref().update(inserts, (error) => {
            if (error) {
              this.errorState = true;
              this.errorMessage = `Problem creating session. ${error}`;
            } else {
              this.successState = true;
              this.successMessage = `${this.newSessionObj.name} created.`;
              // Reset the state
              this.addingSessionFormStep = 1;
              this.addingSession = false;
            }
          });
        }, 400);
      } catch (e) {
        console.error(`Error creating new session: ${e}`);
      }
    },
    // Pinned message
    async setPinnedMessage(messageToPin) {
      console.log('Message to pin: ', messageToPin);
      try {
        const sessionRef = firebase.database().ref(`sessions/${this.sessionToModerate.sessionID}`);
        await sessionRef.update({
          pinnedMessage: messageToPin,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error setting a pinned message. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
      } catch (e) {
        console.error(`Problem pinning message: ${e}`);
      }
    },
    // Moderation logic
    async moderateSession(sessionToModerate) {
      // Checking for old vs new id signifier
      const sessionID = sessionToModerate.sessionID === undefined ? sessionToModerate.key : sessionToModerate.sessionID;
      try {
        this.sessionToModerate = {};
        this.sessionContents = [];
        // Grabbing data
        await firebase.database().ref(`sessions/${sessionID}`).get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              this.sessionToModerate = snapshot.val();
              // Check for session data
              if (snapshot.val().comments || snapshot.val().questions || snapshot.val().contents) {
                this.moderatingSessions = true;
                // Live chat type
                if (snapshot.val().comments) {
                  const contentRef = firebase.database().ref(`sessions/${sessionID}/comments`);
                  contentRef.on('value', (data) => {
                    // this.sessionContents = [];
                    this.liveChats = [];
                    const contents = Object.entries(data.val());
                    contents.forEach((c) => {
                      this.liveChats.push(c[1]);
                    });
                    this.chatspinnedAmount = this.liveChats.filter((item) => item.status === 'pinned').length;
                    this.chatsactiveAmount = this.liveChats.filter((item) => item.status === 'approved').length;
                    this.chatsupcomingAmount = this.liveChats.filter((item) => item.status === 'upcoming').length;
                    this.chatspendingAmount = this.liveChats.filter((item) => item.status === 'pending').length;
                    this.chatsansweredAmount = this.liveChats.filter((item) => item.status === 'answered').length;
                    this.chatsdeletedAmount = this.liveChats.filter((item) => item.status === 'deleted').length;
                  });
                }
                // QA chat type
                if (snapshot.val().questions) {
                  const contentRef = firebase.database().ref(`sessions/${sessionID}/questions`);
                  contentRef.on('value', (data) => {
                    this.questions = [];
                    const contents = Object.entries(data.val());
                    contents.forEach((c) => {
                      this.questions.push(c[1]);
                    });
                    this.questionspinnedAmount = this.questions.filter((item) => item.status === 'pinned').length;
                    this.questionsactiveAmount = this.questions.filter((item) => item.status === 'approved').length;
                    this.questionsupcomingAmount = this.questions.filter((item) => item.status === 'upcoming').length;
                    this.questionspendingAmount = this.questions.filter((item) => item.status === 'pending').length;
                    this.questionsansweredAmount = this.questions.filter((item) => item.status === 'answered').length;
                    this.questionsdeletedAmount = this.questions.filter((item) => item.status === 'deleted').length;
                  });
                }
                // Dynamic content type
                if (snapshot.val().contents) {
                  const contentRef = firebase.database().ref(`sessions/${sessionID}/contents`);
                  contentRef.on('value', (data) => {
                    this.sessionContents = [];
                    const contents = Object.entries(data.val());
                    contents.forEach((c) => {
                      this.sessionContents.push(c[1]);
                    });
                    this.pinnedAmount = this.sessionContents.filter((item) => item.status === 'pinned').length;
                    this.activeAmount = this.sessionContents.filter((item) => item.status === 'approved').length;
                    this.upcomingAmount = this.sessionContents.filter((item) => item.status === 'upcoming').length;
                    this.pendingAmount = this.sessionContents.filter((item) => item.status === 'pending').length;
                    this.answeredAmount = this.sessionContents.filter((item) => item.status === 'answered').length;
                    this.deletedAmount = this.sessionContents.filter((item) => item.status === 'deleted').length;
                  });
                }
                this.sessionContents.sort((a, b) => new Date(a.date) - new Date(b.date));
              } else {
                this.sessionContents = [];
                this.moderatingSessions = true;
              }
            } else {
              this.warningState = true;
              this.warningMessage = 'Currently no data available';
            }
          })
          .catch((e) => {
            if (e) {
              this.errorState = true;
              this.errorMessage = `Problem getting session information. ${e}`;
            }
          });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Problem getting session information. ${e}`;
        }
      }
    },
    stopModeration(session) {
      firebase.database().ref(`sessions/${session.sessionID}`).off('value');
    },
    // Adding dynamic content types
    addDynamicContent() {
      try {
        // Look for type of content
        const youtube = this.contentEmbed.url.includes('youtube');
        const vimeo = this.contentEmbed.url.includes('vimeo');
        if (youtube === true) {
          const id = this.contentEmbed.url.lastIndexOf('=');
          const result = this.contentEmbed.url.substring(id + 1);
          // eslint-disable-next-line no-unused-expressions
          this.contentEmbed.contentType = 'Youtube';
          this.contentEmbed.url = `https://www.youtube.com/embed/${result}`;
        }
        if (vimeo === true) {
          const id = this.contentEmbed.url.lastIndexOf('/');
          const result = this.contentEmbed.url.substring(id + 1);
          // eslint-disable-next-line no-unused-expressions
          this.contentEmbed.contentType = 'Vimeo';
          this.contentEmbed.url = `https://player.vimeo.com/video/${result}`;
        }
        if (youtube !== true && vimeo !== true) {
          this.contentEmbed.contentType = 'Website';
        }
        this.contentEmbed.date = new Date();
        const inserts = {};
        const insertKey = firebase.database().ref(`sessions/${this.sessionToModerate.sessionID}/content`).push().key;
        this.contentEmbed.key = insertKey;
        inserts[`sessions/${this.sessionToModerate.sessionID}/contents/${insertKey}`] = this.contentEmbed;
        firebase.database().ref().update(inserts, (error) => {
          if (error) {
            console.error('Problem inserting media type', error);
          } else {
            this.contentEmbed = {
              key: '',
              name: '',
              url: '',
              status: 'approved',
              contentType: '',
              fileDownload: false,
            };
          }
        });
      } catch (e) {
        console.log(`Error adding dynmaic content: ${e}`);
      }
    },
    // Session updating
    async updateSessionName(nameToUpdateTo) {
      try {
        // Update session name
        const sessionRef = firebase.database().ref(`sessions/${this.sessionToModerate.sessionID}`);
        const parentEventRef = firebase.database().ref(`events/${this.sessionToModerate.eventID}/sessions/${this.sessionToModerate.sessionID}`);
        await sessionRef.update({
          name: nameToUpdateTo,
          tabName: nameToUpdateTo,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating session name. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
        await parentEventRef.update({
          name: nameToUpdateTo,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating session name. ${error}`;
          } else {
            // TODO: Add success message
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating the session name. ${e}`;
        }
      }
    },
    // Session settings
    async updateSessionSetting() {
      try {
        const sessionRef = firebase.database().ref(`sessions/${this.sessionToModerate.sessionID}`);
        await sessionRef.update({
          status: this.sessionToModerate.status,
          anonymous: this.sessionToModerate.anonymous,
          protected: this.sessionToModerate.protected,
          register: this.sessionToModerate.register,
          moderation: this.sessionToModerate.moderation,
        }, (error) => {
          if (error) {
            this.errorState = true;
            this.errorMessage = `Error updating session settings. ${error}`;
          } else {
            this.successState = true;
            this.successMessage = 'Session settings updated';
          }
        });
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating session settings. ${e}`;
        }
      }
    },
    // Session content moderation
    async sessionContentModeration(content) {
      try {
        console.log(content, this.sessionToModerate);
        // Updating a live chat content type
        if (this.sessionToModerate.id === 0) {
          const contentRef = firebase.database().ref(`sessions/${this.sessionToModerate?.sessionID}/comments/${content?.key}`);
          await contentRef.update({
            status: content?.status,
            date: new Date(),
          }, (error) => {
            if (error) {
              console.log(`Error updating content: ${error}`);
            } else {
              // TODO: Add success message
            }
          });
        }
        // Updating a QA content type
        if (this.sessionToModerate.id === 1) {
          const contentRef = firebase.database().ref(`sessions/${this.sessionToModerate?.sessionID}/questions/${content.key}`);
          await contentRef.update({
            status: content?.status,
            date: new Date(),
          }, (error) => {
            if (error) {
              console.log(`Error updating content: ${error}`);
            } else {
              // TODO: Add success message
            }
          });
        }
        // Updating a media content type
        if (this.sessionToModerate.id === 2) {
          const contentRef = firebase.database().ref(`sessions/${this.sessionToModerate?.sessionID}/contents/${content.key}`);
          await contentRef.update({
            status: content?.status,
            date: new Date(),
          }, (error) => {
            if (error) {
              console.log(`Error updating content: ${error}`);
            } else {
              // TODO: Add success message
            }
          });
        }
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating content item. ${e}`;
        }
      }
    },
    async sessionContentCommentModeration(content) {
      try {
        console.log(content, this.sessionToModerate);
        // Updating a QA content type
        if (this.sessionToModerate.id === 1) {
          const contentRef = firebase.database().ref(`sessions/${this.sessionToModerate?.sessionID}/questions/${content.key}`);
          await contentRef.update({
            comment: content?.comment,
            date: new Date(),
          }, (error) => {
            if (error) {
              console.log(`Error updating content: ${error}`);
            } else {
              // TODO: Add success message
            }
          });
        }
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating content item. ${e}`;
        }
      }
    },
    async sessionContentChatModeration(content) {
      try {
        // console.log(content, this.sessionToModerate);
        // Updating a Comments content type
        if (this.sessionToModerate.id === 0) {
          const contentRef = firebase.database().ref(`sessions/${this.sessionToModerate?.sessionID}/comments/${content.key}`);
          await contentRef.update({
            comment: content?.comment,
            date: new Date(),
          }, (error) => {
            if (error) {
              console.log(`Error updating content: ${error}`);
            } else {
              // TODO: Add success message
            }
          });
        }
      } catch (e) {
        if (e) {
          this.errorState = true;
          this.errorMessage = `Error updating content item. ${e}`;
        }
      }
    },
    // Deletion of session
    removeSession(session) {
      this.removingSessions = true;
      this.sessionToRemove = session;
    },
    async confirmSessionRemoval() {
      try {
        const eventSessionRef = firebase.database().ref(`events/${this.sessionToRemove.eventID}/sessions/${this.sessionToRemove.sessionID}`);
        const sessionRef = firebase.database().ref(`sessions/${this.sessionToRemove.sessionID}`);
        eventSessionRef.remove();
        sessionRef.remove();
        this.removingSessions = false;
        this.sessionToRemove = {};
        this.confirmSessionRemovalText = '';
      } catch (e) {
        console.error(`Problem removing session: ${e}`);
      }
    },
    // Event data exporting
    getReportData(event, action) {
      try {
        if (event.sessions) {
          const chatData = [];
          const qaData = [];
          const sheets = [];
          const sessions = Object.values(event.sessions);
          // eslint-disable-next-line no-unused-expressions
          sessions?.forEach((session) => {
            const sessionRef = firebase.database().ref(`sessions/${session.sessionID}`);
            sessionRef.once('value', (snap) => {
              if (snap.exists()) {
                const sessObj = snap.val();
                // Live chat
                if (sessObj.id === 0) {
                  if (sessObj.comments) {
                    const chat = Object.values(sessObj.comments);
                    // eslint-disable-next-line no-unused-expressions
                    chat?.forEach((chatItem) => {
                      chatData.push(chatItem);
                    });
                    if (chatData.length >= 1) {
                      sheets.push({
                        name: sessObj.name,
                        data: chatData,
                      });
                    }
                  }
                }
                // QA
                if (sessObj.id === 1) {
                  if (sessObj.questions) {
                    const questions = Object.values(sessObj.questions);
                    // eslint-disable-next-line no-unused-expressions
                    questions?.forEach((questionItem) => {
                      qaData.push(questionItem);
                    });
                    if (qaData.length >= 1) {
                      sheets.push({
                        name: sessObj.name,
                        data: qaData,
                      });
                    }
                  }
                }
              }
            });
          });
          setTimeout(() => {
            if (sheets.length >= 1) {
              this.canExport = true;
              this.fileName = `${event.name} event data`;
              this.dataToExport = sheets;
              if (action === 'delete') {
                this.exportEventData(sheets, `${event.name} event data`);
              }
            }
          }, 300);
        } else {
          this.canExport = false;
        }
      } catch (e) {
        console.log(`Error getting report data: ${e}`);
      }
    },
    exportEventData() {
      this.exportingData = true;
      try {
        exportExcel(this.dataToExport, this.fileName);
        // exportExcel(data, name);
      } catch (e) {
        console.error(`Problem exporting excel sheet: ${e}`);
        this.reportingError = false;
        this.reportingErrorMessage = `Error exporting data: ${e}`;
      }
      this.exportingData = false;
    },
    // Draggable logic
    // Set the event id on the event we are dragging sessions on
    setEvent(event) {
      this.draggableEventID = event.uid;
      this.draggableEvent = event;
    },
    // Get indexes of sessions we are dragging on
    async checkChanges() {
      try {
        // eslint-disable-next-line no-unused-expressions
        this.draggableEvent.sessions?.forEach((session, index) => {
          // eslint-disable-next-line no-param-reassign
          session.orderID = index;
        });
        // eslint-disable-next-line no-unused-expressions
        this.draggableEvent?.sessions?.forEach((session) => {
          firebase.database().ref(`events/${this.draggableEvent.uid}/sessions/${session.sessionID}`)
            .update({
              orderID: session.orderID,
            }, (error) => {
              if (error) {
                console.error(`Error updating the orderID of the session ${error}`);
              }
            });
        });
      } catch (e) {
        console.error(`Problem updating sessions order: ${e}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}
// Color vars
$cards-background: #FFF;
$primary-font-color: #0B0E31;
$button-color: #5F5DFF;

// Font vars
$primary-font: 'Inter', sans-serif;

.events-page {
  width: 100%;
  height: 90vh;

  padding: 0;
  margin: 0;

  background: #F5F7FB;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .container {
    width: 100%;
  }

  .actions {
    width: 100%;
    height: auto;

    padding: 0 0 20px 0;
    margin-bottom: 10px;

    @media screen and (max-width: 48rem) {
      display: flex;
      flex-direction: column !important;
      align-content: flex-start;
      align-items: center;
      justify-content: center;
    }

    .title {
      font: 500 1.8rem $primary-font;
      color: $primary-font-color;

      // Phone
      @media screen and (max-width: 48rem) {
        font-size: 1.4rem;
      }
    }

    .button {
      background: $button-color !important;

      &:hover, &:active {
        background: darken($button-color, 5) !important;
      }

      @media screen and (max-width: 48rem) {
        width: 100%;
        margin-top: 16px;
      }
    }
  }

  .upper {
    width: 100%;
    height: auto;

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;
    }

    ul {
      display: flex;
      flex-direction: row;
      align-content: center;
      align-items: center;
      justify-content: flex-start;
      overflow: scroll;

      li:not(:last-child) {
        margin-right: 30px;
      }

      li {
        p {
          font: 400 1.3rem "Helvetica Neue", sans-serif;
          color: #0B0E31;
          cursor: pointer;
          margin-bottom: 5px;

          &:hover, &:active {
            color: #7853F6;
          }

          // Phone
          @media screen and (max-width: 48rem) {
            font-size: 1.1rem;
          }
        }
        &.router-link-active {
          color: lighten(#0B0E31, 3);
          border-bottom: 3px solid #7853F6;
        }
      }
    }
  }

  .events-section {
    width: 100%;
    height: auto;

    @media screen and (max-width: 48rem) {
      max-height: 62vh;
      overflow: scroll;
    }

    .quick-add {
      width: 100%;
      height: auto;

      padding: 20px;

      border-radius: 5px;
      border: 1px solid #F0F2F8;
      background: #E5E9EF;

      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;

      cursor: pointer;

      &:hover {
        background: darken(#E5E9EF, 3);
      }

      p {
        font: 400 1.1rem "Helvetica Neue", sans-serif;
        color: #747F8B;
      }
    };

    .events {
      width: 100%;
      height: auto;

      padding: 10px 0 10px 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start;

      ul {
        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;

        li {
          width: calc(97% / 3);
          height: auto;

          .event {
            width: 100%;
            height: auto;

            padding: 15px 13px 15px 13px;

            background: #FFFFFF;
            border-radius: 5px;
            border: 1px solid #F5F6F9;
            box-shadow: -1px 5px 20px 0px rgb(0 0 0 / 3%);

            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .is-active {
              svg {
                fill: #05c46b !important;
                color: #05c46b !important;
              }
            }
            .is-info {
              svg {
                fill: #3E55C4 !important;
                color: #3E55C4 !important;
              }
            }
            .is-completed {
              svg {
                fill: #3587CE !important;
                color: #3587CE !important;
              }
            }
            .is-archived {
              svg {
                fill: #FEDC7D !important;
                color: #FEDC7D !important;
              }
            }
            .is-removed {
              svg {
                fill: #e74c3c !important;
                color: #e74c3c !important;
              }
            }

            .event-name {
              p {
                font: 600 1.5rem "Helvetica Neue", sans-serif;
                color: #0B0F32;

                // Phone
                @media screen and (max-width: 48rem) {
                  font-size: 1.2rem;
                }
              }
            }

            .event-actions {
              position: relative;

              .button {
                cursor: pointer;
              }

              .event-actions-menu {
                width: 160px;
                max-width: 250px;
                height: auto;

                position: absolute;
                top: 23px;
                right: 0;
                z-index: 9;

                background: #FFFFFF;
                border-radius: 5px;
                border: 1px solid #F5F6F9;
                box-shadow: -1px 5px 20px 0px rgb(0 0 0 / 3%);

                ul {
                  width: 100%;

                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  align-content: center;
                  justify-content: center;

                  li {
                    width: 100%;
                    cursor: pointer;
                    padding: 5px;
                    text-align: center;

                    &:hover {
                      background: darken(#f3f3f3, 5);
                    }
                    &:not(:last-child) {
                      margin-bottom: 10px;
                    }
                  }
                }
              }

              i, font-awesome-icon {
                cursor: pointer;
              }
            }

            .event-sessions {
              width: 100%;
              height: auto;

              padding: 5px 0 5px 0;

              .upper {
                //cursor: pointer;

                .event-sessions-title {
                  font: 500 1.1rem "Helvetica Neue", sans-serif;
                }

                border-top: 1px solid lightgrey;
                padding-top: 14px;
                margin-top: 10px;
              }

              .no-session {
                font: 400 1rem "Helvetica Neue", sans-serif;
              }
              .is-primary {
                background: #5F5DFF;
              }

              .sessions {
                cursor: pointer;
                transition: all .3s ease-in-out;

                display: flex;
                flex-direction: column;
                align-content: flex-start;
                justify-content: flex-start;

                .session {
                  width: 100%;
                  margin: 0;

                  .session-name {
                    width: 90%;
                    height: auto;

                    padding: 10px;
                    background: #f3f3f3;
                    border-radius: 3px;

                    p {
                      font:normal 1.2rem "Helvetica Neue", sans-serif;
                      color: #0B0F32 !important;
                    }

                    &:hover {
                      background: darken(#f3f3f3, 10);
                      color: #0B0F32 !important;
                    }
                  }

                  .session-actions {
                    width: fit-content;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;

                    .action-danger {
                      width: 15%;
                      height: 100%;
                      padding: 13px 16px 13px 16px;

                      display: flex;
                      align-items: center;
                      align-content: center;
                      justify-content: center;

                      border-radius: 3px;
                      background: darken(#f3f3f3, 7);

                      svg {
                        color: #e74c3c;
                      }

                      &:hover {
                        background: darken(#f3f3f3, 14);
                      }
                    }
                    .action-info {
                      width: 15%;
                      height: 100%;
                      //padding: 13px 16px 13px 16px;

                      display: flex;
                      align-items: center;
                      align-content: center;
                      justify-content: center;

                      border-radius: 3px;
                      background: lighten(#f3f3f3, 7);

                      svg {
                        color: #3498db;
                      }

                      &:hover {
                        border: 1px solid darken(#f3f3f3, 14);
                      }
                    }
                  }

                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }
                }
              }
            }

            &:not(:last-child) {
              margin-right: 10px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 15px;
            margin-right: 10px;
          }

          // Tablet
          @media screen and (min-width: 49rem) and (max-width: 67.5rem) {
            min-width: 50%;
            max-width: 100%;
          }
          // Phone
          @media screen and (max-width: 48rem) {
            min-width: 100%;
            max-width: 100%;
          }
        }

        .hidden {
          display: none;
          width: 0;
          height: 0;
          z-index: -9999;
        }
      }
    }
  }
}
.event {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .tabs {
    width: 100%;
    height: fit-content;
    max-height: 41px;
    overflow-y: scroll;

    padding: 0;
    margin: 0;

    @media screen and (max-width: 15rem) {
      max-height: 100vh;
      height: 100%;
      overflow: hidden;
      width: 46px;
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      -webkit-writing-mode: vertical-lr;
      writing-mode: vertical-lr;
      writing-mode: tb-lr;
      text-align: left;
      margin-top: -4px;
      border: none !important;
      margin-top: 0px;
      background: white;
    }

    ul {
      border-bottom: none transparent;

      @media screen and (max-width: 15rem) {
        justify-content: center !important;
      }

      li {
        width: 100%;
        min-width: calc(100% / 3);
        max-width: 100%;
        border: none transparent;
        color: var(--accent-color);
        font-weight: bold;

        padding: 10px;

        padding-left: 20px;

        display: flex;
        &:not(:last-child) {
          margin-right: 3px;
        }

        cursor: pointer;

        @media screen and (max-width: 15rem) {
          padding: 30px 6px 30px 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-right: 0 !important;
        }
        @media screen and (max-width: 5rem) {
          margin-right: 0 !important;
          justify-content: center;
        }

        .counter {
          width: 15px;
          height: 15px;
          background: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          padding: 0;
          font-size: 0.8rem;
        }

        .hidden {
          display: none;
          width: 0px;
          height: 0px;
        }
      }
    }
  }
  .event-actions {
    width: 100%;
    height: auto;

    @media screen and (max-width: 15rem) {
      min-height: 100vh;
      max-height: 100vh;
      display: none;
    }

    @media screen and (max-width: 45rem) {
      min-height: 0 !important;
      max-height: 81.8vh !important;
      border: none;
      overflow: scroll;
    }

    .event-contents {
      width: 100%;
      min-height: 98%;
      height: 98%;
      max-height: 98%;
      overflow: scroll;

      padding: 0;
      margin: 0;

      display: flex;
      flex-direction: column;

      .comments-section {
        width: 100%;
        flex-grow: 1;
        margin-bottom: 1%;
        overflow: hidden;

        padding: 10px;

        ul {
          width: 100%;
          height: 98%;
          overflow: scroll;

          &::-webkit-scrollbar {
            display: none;
          }
          li {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }

        img {
          height: auto;
          max-height: fit-content;
          //max-height: 400px;
        }

        .comment {
          width: 100%;
          height: auto;
          margin-top: 10px;
          //margin-bottom: 25px;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-content: center;
          justify-content: flex-start;
          background: transparent;

          .comment-name {
            width: 100%;
            height: auto;

            color: hsl(0, 0%, 20%);
            font: 600 12px "Helvetica Neue", sans-serif;
            text-transform: capitalize;
            padding: 0 0 1px 0;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
            align-content: center;
          }

          .comment-content {
            width: 100%;
            max-width: 99%;
            height: auto;

            font-size: .975rem;
            letter-spacing: 0.05px;
            line-height: 1.65;
            word-wrap: break-word;
            color: #333333;

            float: left;
            display: inline-block;
            position: relative;

            border-bottom: 1px solid lightgray;
            padding-bottom: 7px;
          }
        }
      }
      .required-fields {
        width: 100%;
        height: fit-content;

        padding: 10px 10px 20px 10px;

        //border-top: 1px solid lightgrey;

        textarea { resize: none; }

        .bottom-section {
          width: 95%;
          margin-top: 10px;
        }

        .button {
          color: #333;
          padding: 2px 10px 2px 10px;
          font-weight: 700;
          font-size: 1.1rem !important;
        }
      }
    }
  }
}
.modal-card-body {
  overflow: auto;

  .session-settings {
    @media screen and (max-width: 48rem) {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      align-content: flex-start !important;
      justify-content: flex-start !important;

      p.title {
        font-size: 1.3rem;
      }
      .actions {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  .session-types {
    li {
      width: 100%;
      height: auto;
      padding: 11px;
      background: #F3f3f3f3;
      border-radius: 10px;

      cursor: pointer;
      transition: all .3s ease-in-out;
      .type-name {
        font: 500 1.1rem "Helvetica Neue", sans-serif;
        color: #0B0E31;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      &:hover {
        background: darken( #F3f3f3f3, 5);
      }
    }
  }
  .options {
    width: 100%;
    height: auto;
    padding: 0 0 12px 0;

    .field:not(:last-child) {
      margin-bottom: 0;
    }
  }
  button {
    &.is-primary {
      background-color: #5F5DFF;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:hover, &:active {
        background-color: darken(#5F5DFF, 5);
      }
    }
  }
  .modal-actions {
    padding: 10px 0 0 0;

    max-height: 70vh;
    overflow: scroll;

    .is-primary {
      background: #5F5DFF;

      &:hover {
        background: lighten(#5F5DFF, 3);
      }
    }
    .label {
      font: 600 0.875rem "Helvetica Neue", sans-serif;
      color: #333;
    }
    .session-type-title {
      font:normal 1.2rem "Helvetica Neue", sans-serif;
    }
    .session-types {
      li {
        width: 100%;
        height: auto;
        padding: 11px;
        background: #F3f3f3f3;
        border-radius: 10px;

        cursor: pointer;
        transition: all .3s ease-in-out;
        .type-name {
          font: 500 1.1rem "Helvetica Neue", sans-serif;
          color: #0B0E31;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &:hover {
          background: darken( #F3f3f3f3, 5);
        }
      }
    }
    .session-settings {
      width: 100%;
      height: auto;
      padding: 10px 10px 0 10px;
      background: #f3f3f3;
      border-radius: 5px;
      border: 1px solid lightgray;

      p {
        font:400 1.05rem "Helvetica Neue", sans-serif;
        color: #0B0E31;
      }
    }
    .options {
      width: 100%;
      height: auto;
      padding: 0 0 12px 0;

      .field:not(:last-child) {
        margin-bottom: 0;
      }
    }
    .add-colors {
      width: 100%;
      height: auto;

      padding: 10px;
      margin: 10px 0 10px 0;

      border-radius: 5px;
      background-color: rgba(237,233,254, .5);
      border: 1px solid rgba(237,233,254, 1);
      color: #5F5DFF;
      font-weight: bold;
      font-awesome-icon {
        width: 1rem !important;
      }
    }
    .color-picking {
      width: 100%;
      height: auto;
      padding: 10px 20px 10px 20px;
      background: #F9FAFB;

      .field {
        min-width: 24%;
        margin-right: 1%;
      }
    }
    .picker {
      width: 100%;
      input {
        cursor: pointer;
      }
    }
    .color-picker {
      padding: 0;
      margin: 0;
      background: transparent !important;
      border: none;
    }
  }
  // Session contents
  .comments {
    width: 100%;
    height: auto;
    max-height: 56vh;

    overflow: scroll;
    margin-top: 20px;

    @media screen and (max-width: 48rem) {
      max-height: 46vh;
    }

    // Live chat and qa styling
    .comment {
      width: 100%;
      height: auto;

      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;

      .comment-contents {
        flex-grow: 1;
        padding: 10px 10px 10px 15px;
        background: #f3f3f3;
        border-radius: 5px;
        position: relative;

        .new-status {
          position: absolute;
          top: 0;
          left: -9px;
          width: 15px;
          height: 100%;
          background: #27ae60;
          z-index: 9999;
          padding-right: 15px;
        }

        @media screen and (max-width: 48rem) {
          max-width: 67%;
          overflow: hidden;
        }
      }

      .comment-actions {
        width: fit-content;
        max-width: 20%;
        padding: 10px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-start;

        @media screen and (max-width: 48rem) {
          max-width: 40% !important;
        }
      }
    }
    // Dynamic content styling
    .table-display {
      width: 100%;
      height: auto;

      li:first-child {
        border-bottom: 1px solid lightgrey;
        margin-bottom: 5px;
        padding-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;

        p {
          width: 17%;
          text-align: center;
        }
        p:first-child {
          max-width: 270px;
          min-width: 270px;
          text-align: left;
        }

        p.overflow {
          width: 245px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 300px;
        }
      }
    }
    // Hidden
    .hidden {
      display: none;
      height: 0;
      width: 0;
      z-index: -9999;
    }
  }
  // Dynamic content
  .add {
    width: 100%;
    height: auto;

    padding: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;

    background: #F5F7FB;

    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover, &:active {
      background: darken(#F5F7FB, 3);
    }
  }
  .add-content {
    width: 100%;
    height: auto;

    margin-top: 10px;
    margin-bottom: 15px;
    padding: 15px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;

    background: #F5F7FB;
    border: 1px solid lighten(#5F5DFF, 22);

    .content-form {
      width: 100%;
      margin-top: 20px;
    }
  }
}

// Draggable
.ghost {
  opacity: 0.5;
}
.not-draggable {
  cursor: no-drop;
}
</style>
