import { library } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import the icons needed from the main library
import {
  faPlus,
  faCogs,
  faCircle,
  faTimes,
  faEye,
  faPalette,
  faChevronLeft,
  faChevronCircleLeft,
  faBookReader,
  faChevronCircleDown,
  faTrash,
  faChevronCircleUp,
  faTrashAlt,
  faCheckCircle,
  faEyeSlash,
  faThumbsUp,
  faEllipsisH,
  faSmile,
  faMailBulk,
  faLongArrowAltRight,
  faGrin,
  faExclamation,
  faTextHeight,
  faInfo,
  faFileAlt,
  faExclamationTriangle,
  faUserLock,
  faUserPlus,
  faUserAstronaut,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';

library.add(
  faPlus,
  faCogs,
  faCircle,
  faTimes,
  faEye,
  faPalette,
  faChevronLeft,
  faChevronCircleLeft,
  faBookReader,
  faChevronCircleDown,
  faTrash,
  faChevronCircleUp,
  faTrashAlt,
  faCheckCircle,
  faEyeSlash,
  faThumbsUp,
  faEllipsisH,
  faSmile,
  faMailBulk,
  faLongArrowAltRight,
  faGrin,
  faExclamation,
  faTextHeight,
  faInfo,
  faFileAlt,
  faExclamationTriangle,
  faUserLock,
  faUserPlus,
  faUserAstronaut,
  faThumbtack,
  faDownload,
  faLink,
);

export default FontAwesomeIcon;
