// @ts-ignore
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export function saveAsExcelFile(buffer: any, fileName: string): void {
  const data: Blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  });
  FileSaver.saveAs(data, fileName);
}
export function exportExcel(sheets: any, fileName: string) {
  console.log(fileName);
  // Create a new blank workbook
  const workBook = XLSX.utils.book_new();
  // Create a worksheet for the book
  sheets.forEach((sheet: any) => {
    const wsBook = XLSX.utils.json_to_sheet(sheet.data);
    XLSX.utils.book_append_sheet(workBook, wsBook, sheet.name);
  });
  const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
  saveAsExcelFile(excelBuffer, fileName);
}
